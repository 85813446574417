import { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Layout } from "antd";
import axios from "axios";
import dayjs from "dayjs";

import { getErrorToast } from "../../utils/helper";
import { IAppSettingsDetails } from "../../interfaces/common";
import PrivateLogo from "../../assets/images/LogoPrivate.svg";
import FacebookIcon from "../../assets/images/facebookIcon.svg";
import LinkedinIcon from "../../assets/images/linkedinIcon.svg";
import InstagramIcon from "../../assets/images/instagramIcon.svg";
import Container from "../containers";

import styles from "./footer.module.scss";

const { Footer } = Layout;

const FooterComponent = forwardRef(function FooterComponent(props, ref) {
  const navigate = useNavigate();
  const [details, setDetails] = useState<IAppSettingsDetails>();

  const fetchDetails = () => {
    axios
      .get("customer/app-settings")
      .then((response) => setDetails(response?.data?.data))
      .catch((error) => getErrorToast("fetch-app-details-error", error));
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <Footer className={styles["footer"]} ref={ref as any}>
      <Container style={{ marginBottom: 0 }}>
        <div className={styles["content-wrapper"]}>
          <div>
            <Button
              type="link"
              onClick={() => navigate("/")}
              style={{ padding: 0 }}
            >
              <img
                src={PrivateLogo}
                alt="Emuverse Logo"
                className={`${styles["logo-img"]} cursor-pointer`}
              />
            </Button>
            <p>Copyright © {dayjs().format("YYYY")} Emuverse </p>
          </div>
          <div className={styles["details"]}>
            <p className={styles["company"]}>Emuverse</p>
            <p className={styles["address"]}>{details?.address ?? ""}</p>
            {details && (
              <p className={styles["contact"]}>
                <a href={`mailto:${details.contactEmail}`}>
                  {details.contactEmail}
                </a>
                <span> | </span>
                <a href={`tel:${details.contactNumber}`}>
                  {details.contactNumber}
                </a>
              </p>
            )}
            <div className={styles["icons-wrapper"]}>
              <a
                href="https://www.facebook.com/emulinessocial/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={FacebookIcon}
                  alt="facebook"
                  className={styles["icon"]}
                />
              </a>
              <a
                href="https://www.instagram.com/emulinesindia/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={InstagramIcon}
                  alt="instagram"
                  className={styles["icon"]}
                />
              </a>
              <a
                href="https://in.linkedin.com/company/emulines"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={LinkedinIcon}
                  alt="twitter"
                  className={styles["icon"]}
                />
              </a>
            </div>
          </div>
        </div>

        <p className={styles["disclaimer"]}>
          <span>
            <b>Disclaimer:</b>
          </span>
          <span>
            <i>
              {" "}
              The information on the EMULINES&apos; web site is primarily for an
              added advantage for general tracking as a reference only and there
              could be some inadvertent errors due to human entry mistakes. This
              information is not valid for any legal purpose and under no
              circumstance can be used against the EMULINES and its officials in
              any legal proceedings and in any court of law.
            </i>
          </span>
        </p>
      </Container>
    </Footer>
  );
});

export default FooterComponent;
