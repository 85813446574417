import { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import axios from "axios";

import { ReactComponent as SaveIcon } from "../../assets/images/saveIconCurrent.svg";
import { getErrorToast, getSuccessToast } from "../../utils/helper";

import PasswordsField from "./passwordsField";
import styles from "./changePasswordModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  submitUrl: string;
}

const { Item } = Form;

const ChangePasswordModal = ({ isOpen, onClose, submitUrl }: IProps) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({});
  }, [isOpen]);

  const onFormFinish = (values: any) => {
    setSubmitting(true);
    axios
      .put(submitUrl, {
        password: values.password,
        oldPassword: values.oldPassword,
      })
      .then((resp) => {
        getSuccessToast("password-change-success", resp);
        onClose();
      })
      .catch((error) => getErrorToast("password-change-error", error))
      .finally(() => setSubmitting(false));
  };

  return (
    <Modal
      open={isOpen}
      title="Change Password"
      className={`admin-modal ${styles["modal"]}`}
      styles={{ body: { maxHeight: "calc(80vh - 150px)" } }}
      onCancel={submitting ? undefined : onClose}
      maskClosable={false}
      keyboard={false}
      destroyOnClose
      centered
      footer={
        <Row gutter={16}>
          <Col xs={12}>
            <Button
              className="admin-button primary-default"
              onClick={submitting ? undefined : onClose}
            >
              Cancel
            </Button>
          </Col>
          <Col xs={12}>
            <Button
              type="primary"
              onClick={form.submit}
              className="admin-button"
              loading={submitting}
              icon={<SaveIcon />}
            >
              Save
            </Button>
          </Col>
        </Row>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFormFinish}
        disabled={submitting}
      >
        <Row gutter={24}>
          <Col xs={24}>
            <Item
              name="oldPassword"
              label="Current Password"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter current password",
                },
              ]}
            >
              <Input.Password
                className="admin-input"
                placeholder="Enter current password"
              />
            </Item>
          </Col>
        </Row>

        <PasswordsField />
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
