import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Button, Card, Col } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { FormOutlined } from "@ant-design/icons";

import {
  getApiErrorMsg,
  getErrorToast,
  isValidURL,
} from "../../../utils/helper";
import CustomerBookingStatus from "../../../components/booking-status";
import BookingTrackModal from "../../admin/bookings/booking-track-modal";
import { ReactComponent as TrackIcon } from "../../../assets/images/track-icon-without-circle.svg";
import { RecordType } from "../../../components/custom-antd/table";

import styles from "./home.module.scss";

export default function CustomerHomeBookingTable() {
  const [loadingData, setLoadingData] = useState(true);
  const [data, setData] = useState([]);
  const [tableError, setTableError] = useState("");
  const [bookingRecord, setBookingRecord] = useState<RecordType>();
  const [isTrackModalOpen, setIsTrackModalOpen] = useState(false);

  useEffect(() => {
    setLoadingData(true);
    setTableError("");
    const payload = {
      page: 1,
      limit: 10,
      orderBy: "createdAt",
      order: "desc",
      status: ["CONFIRMED", "DISPATCHED"],
    };
    axios
      .post("customer/bookings/all", payload)
      .then((response) => {
        setData(response?.data?.data || []);
      })
      .catch((error) => {
        setData([]);
        setTableError(getApiErrorMsg(error));
      })
      .finally(() => setLoadingData(false));
  }, []);

  const onTrackClick = (record: RecordType) => {
    if (record.bookingType === "LCL") {
      setBookingRecord(record);
      setIsTrackModalOpen(true);
    } else {
      const url = record.quotation?.shippingLine?.webisteTrackingUrl?.trim();
      if (isValidURL(url)) {
        window.open(url, "_blank");
      } else {
        getErrorToast(
          "invalid_url",
          "Tracking is currently unavailable. Please contact support."
        );
      }
    }
  };

  let cardTitle = "PENDING BOOKINGS";
  if (data?.length) cardTitle += ` (Top ${data.length})`;

  const columns: ColumnsType<any> = [
    {
      title: "Booking Reference",
      dataIndex: "bookingReferenceNumber",
      key: "bookingReferenceNumber",
      fixed: "left",
      width: 140,
      render: (value, record) => (
        <Link to={`/manage-booking/summary-details/${record.id}`}>{value}</Link>
      ),
    },
    {
      title: "Pickup",
      dataIndex: ["bookingPickUpPort", "name"],
      key: "bookingPickUpPort",
      render: (value) => value || "--",
      width: 100,
    },
    {
      title: "Delivery",
      dataIndex: ["bookingDeliveryPort", "name"],
      key: "deliveryPort",
      render: (value) => value || "--",
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => <CustomerBookingStatus status={value} />,
      width: 150,
    },
    {
      title: "Track",
      key: "track",
      width: 110,
      render: (value, record) => (
        <Button
          type="link"
          className={styles["action-button"]}
          onClick={() => onTrackClick(record)}
        >
          <TrackIcon />
          Track Booking
        </Button>
      ),
    },
  ];

  return (
    <Col xs={24} md={14}>
      <Card
        title={cardTitle}
        className={styles["table-card"]}
        extra={
          <Button href="/booking" className={styles["create-btn"]}>
            Create New Booking
            <FormOutlined />
          </Button>
        }
      >
        <Table
          size="small"
          columns={columns}
          loading={loadingData}
          dataSource={data}
          rowKey={(row) => row["id"]}
          pagination={false}
          locale={{ emptyText: tableError }}
          scroll={{ x: 200, y: 210 }}
        />
      </Card>

      {bookingRecord && (
        <BookingTrackModal
          fromCustomer
          isModalOpen={isTrackModalOpen}
          setIsModalOpen={setIsTrackModalOpen}
          bookingData={{
            id: bookingRecord.id,
            bookingReferenceNumber: bookingRecord.bookingReferenceNumber,
            routingType: bookingRecord.routingType,
            shiprocketOrderId: bookingRecord?.shiprocketOrderId,
          }}
        />
      )}
    </Col>
  );
}
