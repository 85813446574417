interface ICurrencyCountryMap {
  [key: string]: {
    name: string;
    symbol: string;
    code: string;
  };
}

export const currencyCountryMap: ICurrencyCountryMap = {
  ADP: {
    name: "Andorran Peseta",
    symbol: "ADP",
    code: "ADP",
  },
  AED: {
    name: "United Arab Emirates Dirham",
    symbol: "AED",
    code: "AED",
  },
  AFA: {
    name: "Afghan Afghani (1927–2002)",
    symbol: "AFA",
    code: "AFA",
  },
  AFN: {
    name: "Afghan Afghani",
    symbol: "AFN",
    code: "AFN",
  },
  ALK: {
    name: "Albanian Lek (1946–1965)",
    symbol: "ALK",
    code: "ALK",
  },
  ALL: {
    name: "Albanian Lek",
    symbol: "ALL",
    code: "ALL",
  },
  AMD: {
    name: "Armenian Dram",
    symbol: "AMD",
    code: "AMD",
  },
  ANG: {
    name: "Netherlands Antillean Guilder",
    symbol: "ANG",
    code: "ANG",
  },
  AOA: {
    name: "Angolan Kwanza",
    symbol: "AOA",
    code: "AOA",
  },
  AOK: {
    name: "Angolan Kwanza (1977–1991)",
    symbol: "AOK",
    code: "AOK",
  },
  AON: {
    name: "Angolan New Kwanza (1990–2000)",
    symbol: "AON",
    code: "AON",
  },
  AOR: {
    name: "Angolan Readjusted Kwanza (1995–1999)",
    symbol: "AOR",
    code: "AOR",
  },
  ARA: {
    name: "Argentine Austral",
    symbol: "ARA",
    code: "ARA",
  },
  ARL: {
    name: "Argentine Peso Ley (1970–1983)",
    symbol: "ARL",
    code: "ARL",
  },
  ARM: {
    name: "Argentine Peso (1881–1970)",
    symbol: "ARM",
    code: "ARM",
  },
  ARP: {
    name: "Argentine Peso (1983–1985)",
    symbol: "ARP",
    code: "ARP",
  },
  ARS: {
    name: "Argentine Peso",
    symbol: "ARS",
    code: "ARS",
  },
  ATS: {
    name: "Austrian Schilling",
    symbol: "ATS",
    code: "ATS",
  },
  AUD: {
    name: "Australian Dollar",
    symbol: "A$",
    code: "AUD",
  },
  AWG: {
    name: "Aruban Florin",
    symbol: "AWG",
    code: "AWG",
  },
  AZM: {
    name: "Azerbaijani Manat (1993–2006)",
    symbol: "AZM",
    code: "AZM",
  },
  AZN: {
    name: "Azerbaijani Manat",
    symbol: "AZN",
    code: "AZN",
  },
  BAD: {
    name: "Bosnia-Herzegovina Dinar (1992–1994)",
    symbol: "BAD",
    code: "BAD",
  },
  BAM: {
    name: "Bosnia-Herzegovina Convertible Mark",
    symbol: "BAM",
    code: "BAM",
  },
  BAN: {
    name: "Bosnia-Herzegovina New Dinar (1994–1997)",
    symbol: "BAN",
    code: "BAN",
  },
  BBD: {
    name: "Barbadian Dollar",
    symbol: "BBD",
    code: "BBD",
  },
  BDT: {
    name: "Bangladeshi Taka",
    symbol: "BDT",
    code: "BDT",
  },
  BEC: {
    name: "Belgian Franc (convertible)",
    symbol: "BEC",
    code: "BEC",
  },
  BEF: {
    name: "Belgian Franc",
    symbol: "BEF",
    code: "BEF",
  },
  BEL: {
    name: "Belgian Franc (financial)",
    symbol: "BEL",
    code: "BEL",
  },
  BGL: {
    name: "Bulgarian Hard Lev",
    symbol: "BGL",
    code: "BGL",
  },
  BGM: {
    name: "Bulgarian Socialist Lev",
    symbol: "BGM",
    code: "BGM",
  },
  BGN: {
    name: "Bulgarian Lev",
    symbol: "BGN",
    code: "BGN",
  },
  BGO: {
    name: "Bulgarian Lev (1879–1952)",
    symbol: "BGO",
    code: "BGO",
  },
  BHD: {
    name: "Bahraini Dinar",
    symbol: "BHD",
    code: "BHD",
  },
  BIF: {
    name: "Burundian Franc",
    symbol: "BIF",
    code: "BIF",
  },
  BMD: {
    name: "Bermudan Dollar",
    symbol: "BMD",
    code: "BMD",
  },
  BND: {
    name: "Brunei Dollar",
    symbol: "BND",
    code: "BND",
  },
  BOB: {
    name: "Bolivian Boliviano",
    symbol: "BOB",
    code: "BOB",
  },
  BOL: {
    name: "Bolivian Boliviano (1863–1963)",
    symbol: "BOL",
    code: "BOL",
  },
  BOP: {
    name: "Bolivian Peso",
    symbol: "BOP",
    code: "BOP",
  },
  BOV: {
    name: "Bolivian Mvdol",
    symbol: "BOV",
    code: "BOV",
  },
  BRB: {
    name: "Brazilian New Cruzeiro (1967–1986)",
    symbol: "BRB",
    code: "BRB",
  },
  BRC: {
    name: "Brazilian Cruzado (1986–1989)",
    symbol: "BRC",
    code: "BRC",
  },
  BRE: {
    name: "Brazilian Cruzeiro (1990–1993)",
    symbol: "BRE",
    code: "BRE",
  },
  BRL: {
    name: "Brazilian Real",
    symbol: "R$",
    code: "BRL",
  },
  BRN: {
    name: "Brazilian New Cruzado (1989–1990)",
    symbol: "BRN",
    code: "BRN",
  },
  BRR: {
    name: "Brazilian Cruzeiro (1993–1994)",
    symbol: "BRR",
    code: "BRR",
  },
  BRZ: {
    name: "Brazilian Cruzeiro (1942–1967)",
    symbol: "BRZ",
    code: "BRZ",
  },
  BSD: {
    name: "Bahamian Dollar",
    symbol: "BSD",
    code: "BSD",
  },
  BTN: {
    name: "Bhutanese Ngultrum",
    symbol: "BTN",
    code: "BTN",
  },
  BUK: {
    name: "Burmese Kyat",
    symbol: "BUK",
    code: "BUK",
  },
  BWP: {
    name: "Botswanan Pula",
    symbol: "BWP",
    code: "BWP",
  },
  BYB: {
    name: "Belarusian New Ruble (1994–1999)",
    symbol: "BYB",
    code: "BYB",
  },
  BYR: {
    name: "Belarusian Ruble",
    symbol: "BYR",
    code: "BYR",
  },
  BZD: {
    name: "Belize Dollar",
    symbol: "BZD",
    code: "BZD",
  },
  CAD: {
    name: "Canadian Dollar",
    symbol: "CA$",
    code: "CAD",
  },
  CDF: {
    name: "Congolese Franc",
    symbol: "CDF",
    code: "CDF",
  },
  CHE: {
    name: "WIR Euro",
    symbol: "CHE",
    code: "CHE",
  },
  CHF: {
    name: "Swiss Franc",
    symbol: "CHF",
    code: "CHF",
  },
  CHW: {
    name: "WIR Franc",
    symbol: "CHW",
    code: "CHW",
  },
  CLE: {
    name: "Chilean Escudo",
    symbol: "CLE",
    code: "CLE",
  },
  CLF: {
    name: "Chilean Unit of Account (UF)",
    symbol: "CLF",
    code: "CLF",
  },
  CLP: {
    name: "Chilean Peso",
    symbol: "CLP",
    code: "CLP",
  },
  CNX: {
    name: "Chinese People’s Bank Dollar",
    symbol: "CNX",
    code: "CNX",
  },
  CNY: {
    name: "Chinese Yuan",
    symbol: "CN¥",
    code: "CNY",
  },
  COP: {
    name: "Colombian Peso",
    symbol: "COP",
    code: "COP",
  },
  COU: {
    name: "Colombian Real Value Unit",
    symbol: "COU",
    code: "COU",
  },
  CRC: {
    name: "Costa Rican Colón",
    symbol: "CRC",
    code: "CRC",
  },
  CSD: {
    name: "Serbian Dinar (2002–2006)",
    symbol: "CSD",
    code: "CSD",
  },
  CSK: {
    name: "Czechoslovak Hard Koruna",
    symbol: "CSK",
    code: "CSK",
  },
  CUC: {
    name: "Cuban Convertible Peso",
    symbol: "CUC",
    code: "CUC",
  },
  CUP: {
    name: "Cuban Peso",
    symbol: "CUP",
    code: "CUP",
  },
  CVE: {
    name: "Cape Verdean Escudo",
    symbol: "CVE",
    code: "CVE",
  },
  CYP: {
    name: "Cypriot Pound",
    symbol: "CYP",
    code: "CYP",
  },
  CZK: {
    name: "Czech Republic Koruna",
    symbol: "CZK",
    code: "CZK",
  },
  DDM: {
    name: "East German Mark",
    symbol: "DDM",
    code: "DDM",
  },
  DEM: {
    name: "German Mark",
    symbol: "DEM",
    code: "DEM",
  },
  DJF: {
    name: "Djiboutian Franc",
    symbol: "DJF",
    code: "DJF",
  },
  DKK: {
    name: "Danish Krone",
    symbol: "DKK",
    code: "DKK",
  },
  DOP: {
    name: "Dominican Peso",
    symbol: "DOP",
    code: "DOP",
  },
  DZD: {
    name: "Algerian Dinar",
    symbol: "DZD",
    code: "DZD",
  },
  ECS: {
    name: "Ecuadorian Sucre",
    symbol: "ECS",
    code: "ECS",
  },
  ECV: {
    name: "Ecuadorian Unit of Constant Value",
    symbol: "ECV",
    code: "ECV",
  },
  EEK: {
    name: "Estonian Kroon",
    symbol: "EEK",
    code: "EEK",
  },
  EGP: {
    name: "Egyptian Pound",
    symbol: "EGP",
    code: "EGP",
  },
  ERN: {
    name: "Eritrean Nakfa",
    symbol: "ERN",
    code: "ERN",
  },
  ESA: {
    name: "Spanish Peseta (A account)",
    symbol: "ESA",
    code: "ESA",
  },
  ESB: {
    name: "Spanish Peseta (convertible account)",
    symbol: "ESB",
    code: "ESB",
  },
  ESP: {
    name: "Spanish Peseta",
    symbol: "ESP",
    code: "ESP",
  },
  ETB: {
    name: "Ethiopian Birr",
    symbol: "ETB",
    code: "ETB",
  },
  EUR: {
    name: "Euro",
    symbol: "€",
    code: "EUR",
  },
  FIM: {
    name: "Finnish Markka",
    symbol: "FIM",
    code: "FIM",
  },
  FJD: {
    name: "Fijian Dollar",
    symbol: "FJD",
    code: "FJD",
  },
  FKP: {
    name: "Falkland Islands Pound",
    symbol: "FKP",
    code: "FKP",
  },
  FRF: {
    name: "French Franc",
    symbol: "FRF",
    code: "FRF",
  },
  GBP: {
    name: "British Pound Sterling",
    symbol: "£",
    code: "GBP",
  },
  GEK: {
    name: "Georgian Kupon Larit",
    symbol: "GEK",
    code: "GEK",
  },
  GEL: {
    name: "Georgian Lari",
    symbol: "GEL",
    code: "GEL",
  },
  GHC: {
    name: "Ghanaian Cedi (1979–2007)",
    symbol: "GHC",
    code: "GHC",
  },
  GHS: {
    name: "Ghanaian Cedi",
    symbol: "GHS",
    code: "GHS",
  },
  GIP: {
    name: "Gibraltar Pound",
    symbol: "GIP",
    code: "GIP",
  },
  GMD: {
    name: "Gambian Dalasi",
    symbol: "GMD",
    code: "GMD",
  },
  GNF: {
    name: "Guinean Franc",
    symbol: "GNF",
    code: "GNF",
  },
  GNS: {
    name: "Guinean Syli",
    symbol: "GNS",
    code: "GNS",
  },
  GQE: {
    name: "Equatorial Guinean Ekwele",
    symbol: "GQE",
    code: "GQE",
  },
  GRD: {
    name: "Greek Drachma",
    symbol: "GRD",
    code: "GRD",
  },
  GTQ: {
    name: "Guatemalan Quetzal",
    symbol: "GTQ",
    code: "GTQ",
  },
  GWE: {
    name: "Portuguese Guinea Escudo",
    symbol: "GWE",
    code: "GWE",
  },
  GWP: {
    name: "Guinea-Bissau Peso",
    symbol: "GWP",
    code: "GWP",
  },
  GYD: {
    name: "Guyanaese Dollar",
    symbol: "GYD",
    code: "GYD",
  },
  HKD: {
    name: "Hong Kong Dollar",
    symbol: "HK$",
    code: "HKD",
  },
  HNL: {
    name: "Honduran Lempira",
    symbol: "HNL",
    code: "HNL",
  },
  HRD: {
    name: "Croatian Dinar",
    symbol: "HRD",
    code: "HRD",
  },
  HRK: {
    name: "Croatian Kuna",
    symbol: "HRK",
    code: "HRK",
  },
  HTG: {
    name: "Haitian Gourde",
    symbol: "HTG",
    code: "HTG",
  },
  HUF: {
    name: "Hungarian Forint",
    symbol: "HUF",
    code: "HUF",
  },
  IDR: {
    name: "Indonesian Rupiah",
    symbol: "IDR",
    code: "IDR",
  },
  IEP: {
    name: "Irish Pound",
    symbol: "IEP",
    code: "IEP",
  },
  ILP: {
    name: "Israeli Pound",
    symbol: "ILP",
    code: "ILP",
  },
  ILR: {
    name: "Israeli Sheqel (1980–1985)",
    symbol: "ILR",
    code: "ILR",
  },
  ILS: {
    name: "Israeli New Sheqel",
    symbol: "₪",
    code: "ILS",
  },
  INR: {
    name: "Indian Rupee",
    symbol: "₹",
    code: "INR",
  },
  IQD: {
    name: "Iraqi Dinar",
    symbol: "IQD",
    code: "IQD",
  },
  IRR: {
    name: "Iranian Rial",
    symbol: "IRR",
    code: "IRR",
  },
  ISJ: {
    name: "Icelandic Króna (1918–1981)",
    symbol: "ISJ",
    code: "ISJ",
  },
  ISK: {
    name: "Icelandic Króna",
    symbol: "ISK",
    code: "ISK",
  },
  ITL: {
    name: "Italian Lira",
    symbol: "ITL",
    code: "ITL",
  },
  JMD: {
    name: "Jamaican Dollar",
    symbol: "JMD",
    code: "JMD",
  },
  JOD: {
    name: "Jordanian Dinar",
    symbol: "JOD",
    code: "JOD",
  },
  JPY: {
    name: "Japanese Yen",
    symbol: "¥",
    code: "JPY",
  },
  KES: {
    name: "Kenyan Shilling",
    symbol: "KES",
    code: "KES",
  },
  KGS: {
    name: "Kyrgystani Som",
    symbol: "KGS",
    code: "KGS",
  },
  KHR: {
    name: "Cambodian Riel",
    symbol: "KHR",
    code: "KHR",
  },
  KMF: {
    name: "Comorian Franc",
    symbol: "KMF",
    code: "KMF",
  },
  KPW: {
    name: "North Korean Won",
    symbol: "KPW",
    code: "KPW",
  },
  KRH: {
    name: "South Korean Hwan (1953–1962)",
    symbol: "KRH",
    code: "KRH",
  },
  KRO: {
    name: "South Korean Won (1945–1953)",
    symbol: "KRO",
    code: "KRO",
  },
  KRW: {
    name: "South Korean Won",
    symbol: "₩",
    code: "KRW",
  },
  KWD: {
    name: "Kuwaiti Dinar",
    symbol: "KWD",
    code: "KWD",
  },
  KYD: {
    name: "Cayman Islands Dollar",
    symbol: "KYD",
    code: "KYD",
  },
  KZT: {
    name: "Kazakhstani Tenge",
    symbol: "KZT",
    code: "KZT",
  },
  LAK: {
    name: "Laotian Kip",
    symbol: "LAK",
    code: "LAK",
  },
  LBP: {
    name: "Lebanese Pound",
    symbol: "LBP",
    code: "LBP",
  },
  LKR: {
    name: "Sri Lankan Rupee",
    symbol: "LKR",
    code: "LKR",
  },
  LRD: {
    name: "Liberian Dollar",
    symbol: "LRD",
    code: "LRD",
  },
  LSL: {
    name: "Lesotho Loti",
    symbol: "LSL",
    code: "LSL",
  },
  LTL: {
    name: "Lithuanian Litas",
    symbol: "LTL",
    code: "LTL",
  },
  LTT: {
    name: "Lithuanian Talonas",
    symbol: "LTT",
    code: "LTT",
  },
  LUC: {
    name: "Luxembourgian Convertible Franc",
    symbol: "LUC",
    code: "LUC",
  },
  LUF: {
    name: "Luxembourgian Franc",
    symbol: "LUF",
    code: "LUF",
  },
  LUL: {
    name: "Luxembourg Financial Franc",
    symbol: "LUL",
    code: "LUL",
  },
  LVL: {
    name: "Latvian Lats",
    symbol: "LVL",
    code: "LVL",
  },
  LVR: {
    name: "Latvian Ruble",
    symbol: "LVR",
    code: "LVR",
  },
  LYD: {
    name: "Libyan Dinar",
    symbol: "LYD",
    code: "LYD",
  },
  MAD: {
    name: "Moroccan Dirham",
    symbol: "MAD",
    code: "MAD",
  },
  MAF: {
    name: "Moroccan Franc",
    symbol: "MAF",
    code: "MAF",
  },
  MCF: {
    name: "Monegasque Franc",
    symbol: "MCF",
    code: "MCF",
  },
  MDC: {
    name: "Moldovan Cupon",
    symbol: "MDC",
    code: "MDC",
  },
  MDL: {
    name: "Moldovan Leu",
    symbol: "MDL",
    code: "MDL",
  },
  MGA: {
    name: "Malagasy Ariary",
    symbol: "MGA",
    code: "MGA",
  },
  MGF: {
    name: "Malagasy Franc",
    symbol: "MGF",
    code: "MGF",
  },
  MKD: {
    name: "Macedonian Denar",
    symbol: "MKD",
    code: "MKD",
  },
  MKN: {
    name: "Macedonian Denar (1992–1993)",
    symbol: "MKN",
    code: "MKN",
  },
  MLF: {
    name: "Malian Franc",
    symbol: "MLF",
    code: "MLF",
  },
  MMK: {
    name: "Myanmar Kyat",
    symbol: "MMK",
    code: "MMK",
  },
  MNT: {
    name: "Mongolian Tugrik",
    symbol: "MNT",
    code: "MNT",
  },
  MOP: {
    name: "Macanese Pataca",
    symbol: "MOP",
    code: "MOP",
  },
  MRO: {
    name: "Mauritanian Ouguiya",
    symbol: "MRO",
    code: "MRO",
  },
  MTL: {
    name: "Maltese Lira",
    symbol: "MTL",
    code: "MTL",
  },
  MTP: {
    name: "Maltese Pound",
    symbol: "MTP",
    code: "MTP",
  },
  MUR: {
    name: "Mauritian Rupee",
    symbol: "MUR",
    code: "MUR",
  },
  MVP: {
    name: "Maldivian Rupee (1947–1981)",
    symbol: "MVP",
    code: "MVP",
  },
  MVR: {
    name: "Maldivian Rufiyaa",
    symbol: "MVR",
    code: "MVR",
  },
  MWK: {
    name: "Malawian Kwacha",
    symbol: "MWK",
    code: "MWK",
  },
  MXN: {
    name: "Mexican Peso",
    symbol: "MX$",
    code: "MXN",
  },
  MXP: {
    name: "Mexican Silver Peso (1861–1992)",
    symbol: "MXP",
    code: "MXP",
  },
  MXV: {
    name: "Mexican Investment Unit",
    symbol: "MXV",
    code: "MXV",
  },
  MYR: {
    name: "Malaysian Ringgit",
    symbol: "MYR",
    code: "MYR",
  },
  MZE: {
    name: "Mozambican Escudo",
    symbol: "MZE",
    code: "MZE",
  },
  MZM: {
    name: "Mozambican Metical (1980–2006)",
    symbol: "MZM",
    code: "MZM",
  },
  MZN: {
    name: "Mozambican Metical",
    symbol: "MZN",
    code: "MZN",
  },
  NAD: {
    name: "Namibian Dollar",
    symbol: "NAD",
    code: "NAD",
  },
  NGN: {
    name: "Nigerian Naira",
    symbol: "NGN",
    code: "NGN",
  },
  NIC: {
    name: "Nicaraguan Córdoba (1988–1991)",
    symbol: "NIC",
    code: "NIC",
  },
  NIO: {
    name: "Nicaraguan Córdoba",
    symbol: "NIO",
    code: "NIO",
  },
  NLG: {
    name: "Dutch Guilder",
    symbol: "NLG",
    code: "NLG",
  },
  NOK: {
    name: "Norwegian Krone",
    symbol: "NOK",
    code: "NOK",
  },
  NPR: {
    name: "Nepalese Rupee",
    symbol: "NPR",
    code: "NPR",
  },
  NZD: {
    name: "New Zealand Dollar",
    symbol: "NZ$",
    code: "NZD",
  },
  OMR: {
    name: "Omani Rial",
    symbol: "OMR",
    code: "OMR",
  },
  PAB: {
    name: "Panamanian Balboa",
    symbol: "PAB",
    code: "PAB",
  },
  PEI: {
    name: "Peruvian Inti",
    symbol: "PEI",
    code: "PEI",
  },
  PEN: {
    name: "Peruvian Nuevo Sol",
    symbol: "PEN",
    code: "PEN",
  },
  PES: {
    name: "Peruvian Sol (1863–1965)",
    symbol: "PES",
    code: "PES",
  },
  PGK: {
    name: "Papua New Guinean Kina",
    symbol: "PGK",
    code: "PGK",
  },
  PHP: {
    name: "Philippine Peso",
    symbol: "PHP",
    code: "PHP",
  },
  PKR: {
    name: "Pakistani Rupee",
    symbol: "PKR",
    code: "PKR",
  },
  PLN: {
    name: "Polish Zloty",
    symbol: "PLN",
    code: "PLN",
  },
  PLZ: {
    name: "Polish Zloty (1950–1995)",
    symbol: "PLZ",
    code: "PLZ",
  },
  PTE: {
    name: "Portuguese Escudo",
    symbol: "PTE",
    code: "PTE",
  },
  PYG: {
    name: "Paraguayan Guarani",
    symbol: "PYG",
    code: "PYG",
  },
  QAR: {
    name: "Qatari Rial",
    symbol: "QAR",
    code: "QAR",
  },
  RHD: {
    name: "Rhodesian Dollar",
    symbol: "RHD",
    code: "RHD",
  },
  ROL: {
    name: "Romanian Leu (1952–2006)",
    symbol: "ROL",
    code: "ROL",
  },
  RON: {
    name: "Romanian Leu",
    symbol: "RON",
    code: "RON",
  },
  RSD: {
    name: "Serbian Dinar",
    symbol: "RSD",
    code: "RSD",
  },
  RUB: {
    name: "Russian Ruble",
    symbol: "RUB",
    code: "RUB",
  },
  RUR: {
    name: "Russian Ruble (1991–1998)",
    symbol: "RUR",
    code: "RUR",
  },
  RWF: {
    name: "Rwandan Franc",
    symbol: "RWF",
    code: "RWF",
  },
  SAR: {
    name: "Saudi Riyal",
    symbol: "SAR",
    code: "SAR",
  },
  SBD: {
    name: "Solomon Islands Dollar",
    symbol: "SBD",
    code: "SBD",
  },
  SCR: {
    name: "Seychellois Rupee",
    symbol: "SCR",
    code: "SCR",
  },
  SDD: {
    name: "Sudanese Dinar (1992–2007)",
    symbol: "SDD",
    code: "SDD",
  },
  SDG: {
    name: "Sudanese Pound",
    symbol: "SDG",
    code: "SDG",
  },
  SDP: {
    name: "Sudanese Pound (1957–1998)",
    symbol: "SDP",
    code: "SDP",
  },
  SEK: {
    name: "Swedish Krona",
    symbol: "SEK",
    code: "SEK",
  },
  SGD: {
    name: "Singapore Dollar",
    symbol: "SGD",
    code: "SGD",
  },
  SHP: {
    name: "St. Helena Pound",
    symbol: "SHP",
    code: "SHP",
  },
  SIT: {
    name: "Slovenian Tolar",
    symbol: "SIT",
    code: "SIT",
  },
  SKK: {
    name: "Slovak Koruna",
    symbol: "SKK",
    code: "SKK",
  },
  SLL: {
    name: "Sierra Leonean Leone",
    symbol: "SLL",
    code: "SLL",
  },
  SOS: {
    name: "Somali Shilling",
    symbol: "SOS",
    code: "SOS",
  },
  SRD: {
    name: "Surinamese Dollar",
    symbol: "SRD",
    code: "SRD",
  },
  SRG: {
    name: "Surinamese Guilder",
    symbol: "SRG",
    code: "SRG",
  },
  SSP: {
    name: "South Sudanese Pound",
    symbol: "SSP",
    code: "SSP",
  },
  STD: {
    name: "São Tomé & Príncipe Dobra",
    symbol: "STD",
    code: "STD",
  },
  SUR: {
    name: "Soviet Rouble",
    symbol: "SUR",
    code: "SUR",
  },
  SVC: {
    name: "Salvadoran Colón",
    symbol: "SVC",
    code: "SVC",
  },
  SYP: {
    name: "Syrian Pound",
    symbol: "SYP",
    code: "SYP",
  },
  SZL: {
    name: "Swazi Lilangeni",
    symbol: "SZL",
    code: "SZL",
  },
  THB: {
    name: "Thai Baht",
    symbol: "THB",
    code: "THB",
  },
  TJR: {
    name: "Tajikistani Ruble",
    symbol: "TJR",
    code: "TJR",
  },
  TJS: {
    name: "Tajikistani Somoni",
    symbol: "TJS",
    code: "TJS",
  },
  TMM: {
    name: "Turkmenistani Manat (1993–2009)",
    symbol: "TMM",
    code: "TMM",
  },
  TMT: {
    name: "Turkmenistani Manat",
    symbol: "TMT",
    code: "TMT",
  },
  TND: {
    name: "Tunisian Dinar",
    symbol: "TND",
    code: "TND",
  },
  TOP: {
    name: "Tongan Paʻanga",
    symbol: "TOP",
    code: "TOP",
  },
  TPE: {
    name: "Timorese Escudo",
    symbol: "TPE",
    code: "TPE",
  },
  TRL: {
    name: "Turkish Lira (1922–2005)",
    symbol: "TRL",
    code: "TRL",
  },
  TRY: {
    name: "Turkish Lira",
    symbol: "TRY",
    code: "TRY",
  },
  TTD: {
    name: "Trinidad & Tobago Dollar",
    symbol: "TTD",
    code: "TTD",
  },
  TWD: {
    name: "New Taiwan Dollar",
    symbol: "NT$",
    code: "TWD",
  },
  TZS: {
    name: "Tanzanian Shilling",
    symbol: "TZS",
    code: "TZS",
  },
  UAH: {
    name: "Ukrainian Hryvnia",
    symbol: "UAH",
    code: "UAH",
  },
  UAK: {
    name: "Ukrainian Karbovanets",
    symbol: "UAK",
    code: "UAK",
  },
  UGS: {
    name: "Ugandan Shilling (1966–1987)",
    symbol: "UGS",
    code: "UGS",
  },
  UGX: {
    name: "Ugandan Shilling",
    symbol: "UGX",
    code: "UGX",
  },
  USD: {
    name: "US Dollar",
    symbol: "$",
    code: "USD",
  },
  USN: {
    name: "US Dollar (Next day)",
    symbol: "USN",
    code: "USN",
  },
  USS: {
    name: "US Dollar (Same day)",
    symbol: "USS",
    code: "USS",
  },
  UYI: {
    name: "Uruguayan Peso (Indexed Units)",
    symbol: "UYI",
    code: "UYI",
  },
  UYP: {
    name: "Uruguayan Peso (1975–1993)",
    symbol: "UYP",
    code: "UYP",
  },
  UYU: {
    name: "Uruguayan Peso",
    symbol: "UYU",
    code: "UYU",
  },
  UZS: {
    name: "Uzbekistan Som",
    symbol: "UZS",
    code: "UZS",
  },
  VEB: {
    name: "Venezuelan Bolívar (1871–2008)",
    symbol: "VEB",
    code: "VEB",
  },
  VEF: {
    name: "Venezuelan Bolívar",
    symbol: "VEF",
    code: "VEF",
  },
  VND: {
    name: "Vietnamese Dong",
    symbol: "₫",
    code: "VND",
  },
  VNN: {
    name: "Vietnamese Dong (1978–1985)",
    symbol: "VNN",
    code: "VNN",
  },
  VUV: {
    name: "Vanuatu Vatu",
    symbol: "VUV",
    code: "VUV",
  },
  WST: {
    name: "Samoan Tala",
    symbol: "WST",
    code: "WST",
  },
  XAF: {
    name: "CFA Franc BEAC",
    symbol: "FCFA",
    code: "XAF",
  },
  XAG: {
    name: "Silver",
    symbol: "XAG",
    code: "XAG",
  },
  XAU: {
    name: "Gold",
    symbol: "XAU",
    code: "XAU",
  },
  XBA: {
    name: "European Composite Unit",
    symbol: "XBA",
    code: "XBA",
  },
  XBB: {
    name: "European Monetary Unit",
    symbol: "XBB",
    code: "XBB",
  },
  XBC: {
    name: "European Unit of Account (XBC)",
    symbol: "XBC",
    code: "XBC",
  },
  XBD: {
    name: "European Unit of Account (XBD)",
    symbol: "XBD",
    code: "XBD",
  },
  XCD: {
    name: "East Caribbean Dollar",
    symbol: "EC$",
    code: "XCD",
  },
  XDR: {
    name: "Special Drawing Rights",
    symbol: "XDR",
    code: "XDR",
  },
  XEU: {
    name: "European Currency Unit",
    symbol: "XEU",
    code: "XEU",
  },
  XFO: {
    name: "French Gold Franc",
    symbol: "XFO",
    code: "XFO",
  },
  XFU: {
    name: "French UIC-Franc",
    symbol: "XFU",
    code: "XFU",
  },
  XOF: {
    name: "CFA Franc BCEAO",
    symbol: "CFA",
    code: "XOF",
  },
  XPD: {
    name: "Palladium",
    symbol: "XPD",
    code: "XPD",
  },
  XPF: {
    name: "CFP Franc",
    symbol: "CFPF",
    code: "XPF",
  },
  XPT: {
    name: "Platinum",
    symbol: "XPT",
    code: "XPT",
  },
  XRE: {
    name: "RINET Funds",
    symbol: "XRE",
    code: "XRE",
  },
  XSU: {
    name: "Sucre",
    symbol: "XSU",
    code: "XSU",
  },
  XTS: {
    name: "Testing Currency Code",
    symbol: "XTS",
    code: "XTS",
  },
  XUA: {
    name: "ADB Unit of Account",
    symbol: "XUA",
    code: "XUA",
  },
  YDD: {
    name: "Yemeni Dinar",
    symbol: "YDD",
    code: "YDD",
  },
  YER: {
    name: "Yemeni Rial",
    symbol: "YER",
    code: "YER",
  },
  YUD: {
    name: "Yugoslavian Hard Dinar (1966–1990)",
    symbol: "YUD",
    code: "YUD",
  },
  YUM: {
    name: "Yugoslavian New Dinar (1994–2002)",
    symbol: "YUM",
    code: "YUM",
  },
  YUN: {
    name: "Yugoslavian Convertible Dinar (1990–1992)",
    symbol: "YUN",
    code: "YUN",
  },
  YUR: {
    name: "Yugoslavian Reformed Dinar (1992–1993)",
    symbol: "YUR",
    code: "YUR",
  },
  ZAL: {
    name: "South African Rand (financial)",
    symbol: "ZAL",
    code: "ZAL",
  },
  ZAR: {
    name: "South African Rand",
    symbol: "ZAR",
    code: "ZAR",
  },
  ZMK: {
    name: "Zambian Kwacha (1968–2012)",
    symbol: "ZMK",
    code: "ZMK",
  },
  ZMW: {
    name: "Zambian Kwacha",
    symbol: "ZMW",
    code: "ZMW",
  },
  ZRN: {
    name: "Zairean New Zaire (1993–1998)",
    symbol: "ZRN",
    code: "ZRN",
  },
  ZRZ: {
    name: "Zairean Zaire (1971–1993)",
    symbol: "ZRZ",
    code: "ZRZ",
  },
  ZWD: {
    name: "Zimbabwean Dollar (1980–2008)",
    symbol: "ZWD",
    code: "ZWD",
  },
  ZWL: {
    name: "Zimbabwean Dollar (2009)",
    symbol: "ZWL",
    code: "ZWL",
  },
  ZWR: {
    name: "Zimbabwean Dollar (2008)",
    symbol: "ZWR",
    code: "ZWR",
  },
};
