import { Link } from "react-router-dom";

import Logo from "../../../assets/images/Logo.svg";

import styles from "../header.module.scss";

interface IProps {
  isLogin?: boolean;
}

const PublicHeader = ({ isLogin }: IProps) => {
  return (
    <header className={styles["header"]}>
      <Link to="/">
        <img
          src={Logo}
          alt="Emuverse Powered by Emulines"
          className="cursor-pointer"
        />
      </Link>

      <p className={styles["general-text"]}>
        {`${isLogin ? "Don't have an account?" : "Already have an account?"} `}
        <Link className={styles["link"]} to={isLogin ? "/signup" : "/login"}>
          {isLogin ? "Sign Up" : "Log In"}
        </Link>
      </p>
    </header>
  );
};

export default PublicHeader;
