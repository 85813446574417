import { useState } from "react";
import { Button, Form, Input } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";

import { useAppContext } from "../../hooks/appContext";
import { getErrorToast } from "../../utils/helper";
import { ILoginData } from "../../interfaces/user";
import { loginTokenKey } from "../../constants/index";
import MobileInput from "../../components/form-fields/mobile-input";

import styles from "./login.module.scss";

const { Item } = Form;

const Login = () => {
  const { loadCurrentUser } = useAppContext();
  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);
  const [loginWithMobile, setLoginWithMobile] = useState(false);

  const onSubmit = (data: ILoginData) => {
    setSubmitting(true);
    axios
      .post(`users/login`, data)
      .then((response) => {
        localStorage.setItem(loginTokenKey, response?.data?.data?.token);
        if (loadCurrentUser) {
          loadCurrentUser();
        }
      })
      .catch((err) => getErrorToast("signin-error", err))
      .finally(() => setSubmitting(false));
  };

  return (
    <Form
      className={styles["login-form"]}
      layout="vertical"
      onFinish={onSubmit}
      disabled={submitting}
      form={form}
      scrollToFirstError
    >
      <h1>Log In</h1>
      {loginWithMobile ? (
        <Item
          label="Mobile"
          name="emailOrPhone"
          rules={[
            {
              required: true,
              message: "Please enter your mobile number",
            },
            () => ({
              validator(_, value) {
                if (
                  value &&
                  !isValidPhoneNumber(
                    `${value}`?.startsWith("+") ? value : `+${value}`
                  )
                ) {
                  return Promise.reject(
                    new Error("Please input valid mobile number!")
                  );
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
          validateTrigger="onSubmit"
        >
          <MobileInput wrapperClass={styles["mobile-input-wrapper"]} />
        </Item>
      ) : (
        <Item
          label="Email address"
          name="emailOrPhone"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Please enter your email address",
            },
            { type: "email", message: "Please enter a valid email address" },
          ]}
          validateTrigger="onSubmit"
        >
          <Input placeholder="Enter email address" />
        </Item>
      )}

      <Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            whitespace: true,
            message: "Please enter your password",
          },
        ]}
        style={{ marginBottom: 0 }}
        validateTrigger="onSubmit"
      >
        <Input.Password placeholder="Enter password" />
      </Item>
      <Link to="/forgot-password">Forgot Password?</Link>
      <Button
        type="primary"
        htmlType="submit"
        loading={submitting}
        className={styles["login-button"]}
      >
        Log In
      </Button>
      <Button
        type="link"
        style={{ width: "100%" }}
        onClick={() => {
          form.resetFields();
          setLoginWithMobile(!loginWithMobile);
        }}
      >
        Login with {loginWithMobile ? "Email" : "Mobile"}
      </Button>
    </Form>
  );
};

export default Login;
