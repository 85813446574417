import AdminMainHeader from "../../headers/private/admin/main-header";
import SideNavBar from "../../side-navbar";

import styles from "./adminLayout.module.scss";

interface IProps {
  children?: React.ReactNode;
}

const AdminLayout = ({ children }: IProps) => {
  return (
    <>
      <div className={styles["fixed-header"]}>
        <AdminMainHeader />
      </div>
      <div className={styles["admin-body"]}>
        <SideNavBar />
        <div className={styles["active-body-wrapper"]}>
          <div className={styles["active-body"]}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
