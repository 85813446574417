import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Result } from "antd";

import SuccessIcon from "../../assets/images/success.svg";
import { ReactComponent as BackArrow } from "../../assets/images/arrowLeftLight.svg";
import { useQuery, getApiErrorMsg } from "../../utils/helper";
import Spinner from "../../components/custom-antd/spinner";

import styles from "./verificationSuccess.module.scss";

const VerificationSuccess = () => {
  const query = useQuery();
  const token = query.get("token");

  const [validatingToken, setValidatingToken] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    setValidatingToken(true);
    axios
      .get(`users/verify-email`, { params: { token } })
      .then(() => setValidatingToken(false))
      .catch((err) => setError(getApiErrorMsg(err)))
      .finally(() => setValidatingToken(false));
  }, []);

  const successIcon = () => {
    return error
      ? {}
      : {
          icon: <img src={SuccessIcon} alt="Success" />,
        };
  };

  return (
    <>
      {validatingToken ? (
        <Spinner />
      ) : (
        <Result
          status={error ? "error" : "success"}
          title={error ? "" : "Congrats, your Email has been verified!"}
          className={styles["result"]}
          {...successIcon()}
          subTitle={error || "You can login now!"}
          extra={[
            <Button
              key="back_to_login"
              type="primary"
              href="/"
              htmlType="submit"
              icon={<BackArrow />}
              className={styles["login-btn"]}
            >
              Back to Login
            </Button>,
          ]}
        />
      )}
    </>
  );
};

export default VerificationSuccess;
