import { Form, Select, SelectProps } from "antd";

import { ReactComponent as SelectIcon } from "../../../assets/images/selectIcon.svg";

type ConditionalProps =
  | {
      withAll?: never;
      fieldName?: never;
    }
  | {
      withAll: boolean;
      fieldName: string | (string | number)[];
    };

type IProps = SelectProps & ConditionalProps;

export default function CustomSelect(props: Readonly<IProps>) {
  const { loading, withAll, fieldName, options, ...rest } = props;
  const form = Form.useFormInstance();

  const onChange = (value: any, selectedValueOption: any) => {
    if (props.onChange) {
      props.onChange(value, selectedValueOption);
    }
    if (withAll && form && options) {
      const allIndex = value.indexOf("ALL");

      if (allIndex > -1 && value.length > 1) {
        form.setFieldValue(fieldName, [value[value.length - 1]]);
      } else if (!value.length || value.length === options.length) {
        form.setFieldValue(fieldName, ["ALL"]);
      }
    }
  };

  return (
    <Select
      suffixIcon={loading ? undefined : <SelectIcon />}
      options={[
        ...(withAll && options && options.length > 1
          ? [
              {
                label: "All",
                value: "ALL",
              },
            ]
          : []),
        ...(options ?? []),
      ]}
      {...rest}
      onChange={onChange as any}
      {...(loading ? { value: undefined, loading: true, disabled: true } : {})}
    />
  );
}
