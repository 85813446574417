import { createContext, useContext } from "react";

import { ICountry } from "../interfaces/common";
import { IUser } from "../interfaces/user";

interface IProps {
  loadingUser: boolean;
  loadCurrentUser?: (hideLoader?: boolean) => void;
  user: IUser | undefined;
  setUser: (user: IUser | undefined) => void;
  countriesOptions: ICountry[];
  indianCountryId?: string;
  contentWidth: number;
  contentHeight: number;
  possibleDoorCountries?: string[];
}

const defaultAppContext = {
  loadingUser: false,
  loadCurrentUser: () => null,
  user: undefined,
  setUser: () => null,
  countriesOptions: [],
  indianCountryId: undefined,
  possibleDoorCountries: [],
  contentWidth: 0,
  contentHeight: 0,
};

export const AppContext = createContext<IProps>(defaultAppContext);

export const useAppContext = () => {
  return useContext(AppContext);
};
