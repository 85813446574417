import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, Popover } from "antd";
import { LockTwoTone } from "@ant-design/icons";
import axios from "axios";

import {
  getErrorToast,
  getSuccessToast,
  handleLogout,
} from "../../../../../utils/helper";
import Logo from "../../../../../assets/images/LogoAdmin.svg";
import { ReactComponent as SyncIcon } from "../../../../../assets/images/refresh-circle.svg";
import { ReactComponent as LogOut } from "../../../../../assets/images/sign-out.svg";
import { useAppContext } from "../../../../../hooks/appContext";
import ChangePasswordModal from "../../../../change-password-modal";

import styles from "./adminMainHeader.module.scss";

const AdminMainHeader = () => {
  const navigate = useNavigate();
  const { user, setUser } = useAppContext();
  const { pathname } = useLocation();

  const [callingApi, setCallingApi] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isChangePassOpen, setIsChangePassOpen] = useState(false);

  const onSync = () => {
    setCallingApi(true);
    axios
      .get(`scheduler`)
      .then((response) =>
        getSuccessToast(
          "refresh-database",
          response,
          "Database Sync started successfully!"
        )
      )
      .catch((error) => getErrorToast("refresh-database", error))
      .finally(() => setCallingApi(false));
  };
  const userName = `${user?.firstName} ${user?.lastName ?? ""}`;
  const userEmail = user?.email;
  const initials = `${user?.firstName?.[0]}${user?.lastName?.[0] ?? ""}`;

  return (
    <>
      <header className={styles["header"]}>
        <Button
          type="link"
          className={`${styles["logo-section"]} ${
            pathname !== "/reset-password-success" ? "cursor-pointer" : ""
          }`}
          onClick={() => navigate("/home")}
        >
          <img src={Logo} alt="emuverse" className={styles["header-logo"]} />
        </Button>

        <div className={styles["right-section"]}>
          <Button
            className={`admin-button primary-default ${styles["sync-btn"]}`}
            onClick={onSync}
            loading={callingApi}
            icon={<SyncIcon />}
          >
            Sync EMU Master
          </Button>

          <Popover
            placement="bottomRight"
            trigger="click"
            open={isPopoverOpen}
            onOpenChange={setIsPopoverOpen}
            overlayClassName={styles["profile-popover"]}
            getPopupContainer={(triggerNode) => triggerNode.parentNode as any}
            title={
              <div className={styles["profile-popover-title-wrapper"]}>
                <Avatar
                  src={user?.profilePicture}
                  style={{ background: user?.rgbIcon }}
                  size={40}
                  className={styles["profile-popover-avatar"]}
                >
                  {initials}
                </Avatar>
                <div style={{ overflowWrap: "anywhere" }}>
                  <div className={styles["profile-popover-name"]}>
                    {userName}
                  </div>
                  <div className={styles["profile-popover-email"]}>
                    {userEmail}
                  </div>
                </div>
              </div>
            }
            content={
              <div className={styles["profile-popover-content-wrapper"]}>
                <div className={styles["profile-popover-content-password"]}>
                  <LockTwoTone />
                  <Button
                    type="link"
                    onClick={() => {
                      setIsPopoverOpen(false);
                      setIsChangePassOpen(true);
                    }}
                  >
                    Change Password
                  </Button>
                </div>
                <Button
                  type="link"
                  className={styles["profile-popover-content-logout"]}
                  onClick={() => handleLogout(setUser)}
                >
                  <LogOut width={14} height={14} />
                  <p>Logout</p>
                </Button>
              </div>
            }
            arrow={{ pointAtCenter: true }}
          >
            <Avatar
              src={user?.profilePicture}
              style={{ background: user?.rgbIcon, minWidth: 34 }}
              size={34}
              className="cursor-pointer"
            >
              {initials}
            </Avatar>
          </Popover>
        </div>
      </header>

      <ChangePasswordModal
        isOpen={isChangePassOpen}
        onClose={() => setIsChangePassOpen(false)}
        submitUrl="admin/me"
      />
    </>
  );
};

export default AdminMainHeader;
