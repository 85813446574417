import { CSSProperties } from "react";

import styles from "./containers.module.scss";

interface IProps {
  children: React.ReactNode;
  isContent?: boolean;
  className?: string;
  style?: CSSProperties;
}

const Container = ({ children, isContent, className, style }: IProps) => {
  return (
    <div
      className={`${styles["container"]} ${className ?? ""} ${
        isContent ? styles["content-container"] : ""
      }`}
      style={style ?? {}}
    >
      {children}
    </div>
  );
};

export default Container;
