import { useLocation } from "react-router-dom";

import Thunder from "../../../assets/images/Lightning.svg";
import PublicHeader from "../../headers/public";

import styles from "./authLayout.module.scss";

interface IProps {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: IProps) => {
  const location = useLocation();

  return (
    <div className={styles["auth-wrapper"]}>
      <div className={styles["left-content"]}>
        <PublicHeader isLogin={["/", "/login"].includes(location.pathname)} />
        {children}
      </div>
      <div className={styles["right-content"]}>
        <div className={styles["title"]}>
          <img src={Thunder} alt="Thunder" />
          <span>Freight and Shipping Management Solutions</span>
        </div>
        <div className={styles["description"]}>
          Experience logistics like never before with our cutting-edge digital
          freight platform, specifically designed to elevate your supply chain
          operations
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
