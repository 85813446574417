import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";

import { allRoutes } from "../../constants/permission-routes";
import { useAppContext } from "../../hooks/appContext";
import { IAppRoute } from "../../interfaces/common";

import styles from "./sideNavbar.module.scss";

const SideNavBar = () => {
  const { pathname } = useLocation();
  const { user } = useAppContext();

  const [menus, setMenus] = useState<IAppRoute[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([
    allRoutes[0].key,
  ]);

  const recursiveNavItems = (items: IAppRoute[]) => {
    const result: IAppRoute[] = [];
    items.forEach((el) => {
      if (!el.isNotNav) {
        const IconComponent = el.icon;
        const childs = [];
        if (el.children?.length) {
          childs.push(...recursiveNavItems(el.children));
        }
        result.push({
          ...el,
          label: el.route ? <Link to={el.route}>{el.label}</Link> : el.label,
          icon: el.icon ? <IconComponent width={20} height={20} /> : null,
          children: childs.length ? childs : undefined,
        });
      }
    });
    return result;
  };

  useEffect(() => {
    const tempArr = recursiveNavItems([allRoutes[0], ...(user?.routes ?? [])]);
    setMenus(tempArr);
  }, [user?.routes]);

  useEffect(() => {
    const item = menus.find((el) => pathname.startsWith(el.key));
    if (item) {
      setSelectedKeys([item.key]);
    } else {
      menus.find((el) => {
        const subMenu = el.children?.find(
          (child) => !!pathname.startsWith(child.key)
        );
        if (subMenu) {
          setSelectedKeys([subMenu.key]);
          setOpenKeys([el.key]);
        }
        return !!subMenu;
      });
    }
  }, [pathname, menus]);

  return (
    <div className={styles["side-navbar"]}>
      <Menu
        items={menus}
        mode="inline"
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={setOpenKeys}
      />
    </div>
  );
};

export default SideNavBar;
