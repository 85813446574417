import { useEffect, useState } from "react";
import { Col, Modal, Row, Timeline, TimelineItemProps } from "antd";
import dayjs from "dayjs";
import axios from "axios";

import {
  bookingStatus,
  exportTrackingStatus,
  importTrackingStatus,
} from "../../../../constants";
import { ITrackingDetails } from "../../../../interfaces/common";
import { getErrorToast } from "../../../../utils/helper";
import Spinner from "../../../../components/custom-antd/spinner";
import { timeTZRegex } from "../../../../utils/regex";

import styles from "./bookingTrackModal.module.scss";

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: (val: boolean) => void;
  bookingData?: {
    id: string;
    bookingReferenceNumber: string;
    routingType: "IMPORT" | "EXPORT";
    shiprocketOrderId?: number;
  };
  fromCustomer?: boolean;
}

interface IGetChildrenItems {
  getInnerItemsByBookingStatus: (
    status: string
  ) => ITrackingDetails[] | undefined;
  status: string;
  routingType: "IMPORT" | "EXPORT";
}

const BookingTrackModal = (props: IProps) => {
  const { isModalOpen, setIsModalOpen, bookingData, fromCustomer } = props;

  const [loading, setLoading] = useState(false);
  const [trackingDetails, setTrackingDetails] = useState<ITrackingDetails[]>();

  useEffect(() => {
    if (isModalOpen && bookingData) {
      fetchTrackingHistory();
    } else {
      setTrackingDetails(undefined);
    }
  }, [isModalOpen, bookingData]);

  const onClose = () => {
    setIsModalOpen(false);
  };

  const getInnerItemsByBookingStatus = (bookingStatus: string) => {
    return trackingDetails?.filter((el) => el.bookingStatus === bookingStatus);
  };

  const isDotActive = (bookingStatus: string) => {
    return getInnerItemsByBookingStatus(bookingStatus)?.length !== 0;
  };

  const isCancelled = trackingDetails?.find(
    (el) => el.bookingStatus === "CANCELLED"
  );

  // const isShipRocketImportOrder =
  //   bookingData?.shiprocketOrderId && bookingData.routingType === "IMPORT";
  // const isShipRocketExportOrder =
  //   bookingData?.shiprocketOrderId && bookingData.routingType === "EXPORT";

  const possibleStatus = isCancelled
    ? ["CONFIRMED", "CANCELLED"]
    : // : isShipRocketImportOrder
      // ? [
      //     "CONFIRMED",
      //     "DISPATCHED",
      //     "DELIVERED",
      //     "DOOR_DESTINATION_CREATED",
      //     "DOOR_DESTINATION_PICKUP",
      //     "DOOR_DESTINATION_DELIVERY",
      //   ]
      // : isShipRocketExportOrder
      // ? [
      //     "CONFIRMED",
      //     "DOOR_ORIGIN_CREATED",
      //     "DOOR_ORIGIN_PICKUP",
      //     "DOOR_ORIGIN_DELIVERY",
      //     "DISPATCHED",
      //     "DELIVERED",
      //   ]
      ["CONFIRMED", "DISPATCHED", "DELIVERED"];

  const timelineItems: TimelineItemProps[] = bookingData
    ? possibleStatus.map((status) => ({
        dot: <StatusDot active={isDotActive(status)} status={status} />,
        children: (
          <GetChildrenItems
            status={status}
            routingType={bookingData.routingType}
            getInnerItemsByBookingStatus={getInnerItemsByBookingStatus}
          />
        ),
      }))
    : [];

  const fetchTrackingHistory = () => {
    if (bookingData) {
      setLoading(true);
      axios
        .get(
          `${fromCustomer ? "customer" : "admin"}/bookings/${
            bookingData.id
          }/tracking-history`
        )
        .then((response) => setTrackingDetails(response?.data?.data))
        .catch((error) => getErrorToast("fetch-tracking-error", error))
        .finally(() => setLoading(false));
    }
  };

  return (
    <Modal
      open={isModalOpen}
      title={`Tracking History - ${bookingData?.bookingReferenceNumber}`}
      className={`${styles["bookings-modal-wrapper"]} admin-modal`}
      style={{ maxWidth: "600px" }}
      styles={{
        body: { maxHeight: "calc(80vh - 150px)", padding: "25px 30px" },
      }}
      width="70%"
      onCancel={onClose}
      footer={null}
      maskClosable={false}
      keyboard={false}
      destroyOnClose
      centered
    >
      {loading ? <Spinner /> : <Timeline items={timelineItems} />}
    </Modal>
  );
};

const StatusDot = ({ active, status }: { active: boolean; status: string }) => {
  let backgroundColor;
  if (status === "CANCELLED") {
    backgroundColor = "#FF4D4F";
  } else if (active) {
    backgroundColor = "#39C117";
  } else {
    backgroundColor = "#091E4224";
  }

  return (
    <p
      style={{
        width: "20px",
        height: "20px",
        backgroundColor: backgroundColor,
        borderRadius: "50%",
      }}
    />
  );
};

const GetChildrenItems = (props: IGetChildrenItems) => {
  const { getInnerItemsByBookingStatus, status, routingType } = props;

  const items = getInnerItemsByBookingStatus(status);
  const trackingStatusMap =
    routingType === "IMPORT" ? importTrackingStatus : exportTrackingStatus;

  return (
    <div className={styles["tracking-item-wrapper"]}>
      <p className={styles["tracking-item-header"]}>
        {bookingStatus?.[status].statusTitle}{" "}
        <span className={styles["lighter-text"]}>
          {items?.[0]?.trackingDate &&
            dayjs(items[0].trackingDate).format("DD MMM YYYY - HH:mm")}
        </span>
      </p>
      <div className={styles["tracking-item-inner-wrapper"]}>
        {!!items?.length &&
          items.map((el) => (
            <div key={el.trackingStatus} style={{ margin: "12px 0px" }}>
              <p className={styles["tracking-item-light-header"]}>
                {el.trackingStatus &&
                  trackingStatusMap?.[el.trackingStatus]?.title}
              </p>
              {el.trackingDate && (
                <p className={styles["lighter-text"]}>
                  {dayjs(el.trackingDate).format("DD MMM YYYY - HH:mm")}
                </p>
              )}
              {el.trackingData &&
                el.trackingStatus &&
                Object.keys(el.trackingData).length !== 0 && (
                  <Row
                    className={styles["tracking-data-wrapper"]}
                    gutter={[16, 16]}
                  >
                    {Object.keys(el.trackingData).map((dataKey) => {
                      const dataValue = timeTZRegex.test(
                        el.trackingData?.[dataKey]
                      )
                        ? dayjs(el.trackingData?.[dataKey]).format(
                            "DD MMM YYYY - HH:mm"
                          )
                        : el.trackingData?.[dataKey];
                      return (
                        <Col key={dataKey} xs={24} md={12} lg={8}>
                          <p className={styles["data-heading"]}>
                            {el.trackingStatus &&
                              (trackingStatusMap[el.trackingStatus]
                                ?.labelForKey?.[dataKey] ||
                                dataKey)}
                          </p>
                          <p className={styles["data-value"]}>
                            {dataValue || "--"}
                          </p>
                        </Col>
                      );
                    })}
                  </Row>
                )}
              {el.trackingNote && (
                <p className={styles["lighter-text"]}>
                  Note : {el.trackingNote}
                </p>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default BookingTrackModal;
