import { memo, useEffect, useState } from "react";
import axios from "axios";
import { Button } from "antd";

import HomePageLogo from "../../../assets/images/Homepage.svg";
import { useAppContext } from "../../../hooks/appContext";
import { getErrorToast } from "../../../utils/helper";
import { IAppSettingsDetails } from "../../../interfaces/common";

import CustomerHomeCharts from "./customer-home-charts";
import styles from "./home.module.scss";

const MemoizedCustomerHomeCharts = memo(CustomerHomeCharts);

const HomePage = () => {
  const { user } = useAppContext();
  const [details, setDetails] = useState<IAppSettingsDetails>();
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  const fetchDetails = () => {
    axios
      .get("customer/app-settings")
      .then((response) => setDetails(response?.data?.data))
      .catch((error) => getErrorToast("fetch-app-details-error", error));
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const name = `${user?.firstName ?? ""} ${user?.lastName ?? ""}`;

  const getAnimationDuration = (text: string) => {
    return `${text.length * 0.12}s`;
  };

  return (
    <>
      <div className={styles["top-wrapper"]}>
        <h1 className={styles["name"]}>{`Hi, ${name}!`}</h1>

        <div className={styles["img-wrapper"]}>
          <img
            src={HomePageLogo}
            width={250}
            alt="HomePage"
            className={styles["home-img"]}
          />
        </div>
      </div>

      {details?.bannerHeadline && (
        <Button
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={styles["banner-container"]}
        >
          <div className={styles["marquee-container"]}>
            <p
              className={`${styles["marquee"]} ${
                isPaused ? `${styles["paused"]}` : ""
              }`}
              style={{
                animationDuration: getAnimationDuration(details.bannerHeadline),
              }}
            >
              {details.bannerHeadline}
            </p>
          </div>
        </Button>
      )}

      <MemoizedCustomerHomeCharts />
    </>
  );
};

export default HomePage;
