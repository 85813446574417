import { useEffect, useRef, useState } from "react";
import { Layout } from "antd";

import PrivateHeader from "../../headers/private";
import FooterComponent from "../../footer";
import Container from "../../containers";

import styles from "./customerLayout.module.scss";

interface IProps {
  children: React.ReactNode;
}

const CustomerLayout = ({ children }: IProps) => {
  const headerRef = useRef<HTMLElement>(null);
  const footerRef = useRef<HTMLElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  useEffect(() => {
    setHeaderHeight(headerRef.current?.getBoundingClientRect()?.height ?? 0);
    setFooterHeight(footerRef.current?.getBoundingClientRect()?.height ?? 0);
  }, [headerRef.current?.innerHTML, footerRef.current?.innerHTML]);

  return (
    <Layout className={styles["layout"]}>
      <PrivateHeader ref={headerRef} />
      <Layout.Content className={styles["content-wrapper"]}>
        <Container
          isContent
          style={{
            minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px - 30px)`,
          }}
        >
          {children}
        </Container>
      </Layout.Content>
      <FooterComponent ref={footerRef} />
    </Layout>
  );
};

export default CustomerLayout;
