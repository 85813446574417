import { useEffect } from "react";
import PhoneInput, {
  FeatureProps,
  Value,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { useAppContext } from "../../../hooks/appContext";

import styles from "./mobileInput.module.scss";

interface IProps extends FeatureProps<any> {
  value?: any;
  onChange?: (e: any) => void;
  wrapperClass?: string;
  selectedCountry?: string | null;
}

const MobileInput = (props: IProps) => {
  const { value, onChange, wrapperClass, selectedCountry, ...rest } = props;
  const { countriesOptions } = useAppContext();
  const countryCode =
    selectedCountry &&
    countriesOptions.find((el) => el.id === selectedCountry)?.code;

  const country =
    countryCode ||
    (value &&
      parsePhoneNumber(`${value}`?.startsWith("+") ? value : `+${value}`)
        ?.country) ||
    "IN";
  const phoneNumber =
    value &&
    parsePhoneNumber(`${value}`?.startsWith("+") ? value : `+${value}`)?.number;

  const onCustomChange = (val?: Value) => {
    if (onChange) {
      onChange({ target: { value: val as string } });
    }
  };

  useEffect(() => {
    if (value && countryCode) {
      const countryFromValue = parsePhoneNumber(
        `${value}`?.startsWith("+") ? value : `+${value}`
      )?.country;
      if (countryFromValue !== countryCode) {
        onCustomChange();
      }
    }
  }, [selectedCountry, countryCode]);

  return (
    <PhoneInput
      international
      withCountryCallingCode
      countryCallingCodeEditable={!countryCode}
      className={`${styles.mobile} ${wrapperClass ?? ""} ${
        countryCode && styles["disable-flag-selection"]
      }`}
      defaultCountry={country}
      value={phoneNumber ?? value}
      placeholder="Enter mobile number"
      onChange={onCustomChange}
      {...rest}
    />
  );
};

export default MobileInput;
