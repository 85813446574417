import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider } from "antd";
import App from "./App";

import "./axios/interceptor";
import "./styles/index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ConfigProvider
    form={{ className: "custom-antd-form", requiredMark: false }}
    radio={{ className: "custom-antd-radio" }}
    input={{ className: "custom-antd-input" }}
    divider={{ className: "custom-antd-divider" }}
    button={{ className: "custom-antd-button" }}
    select={{ className: "custom-antd-select" }}
    datePicker={{ className: "custom-antd-datePicker" }}
    checkbox={{ className: "custom-antd-checkbox" }}
    modal={{ className: "custom-antd-modal" }}
    theme={{
      token: { colorPrimary: "#0C66E4", fontFamily: '"Inter", sans-serif' },
    }}
  >
    <Router>
      <App />
    </Router>
  </ConfigProvider>
);
