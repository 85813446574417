import { lazy } from "react";

import { ReactComponent as HomeIcon } from "../assets/images/home-menu.svg";
import { ReactComponent as PortsIcon } from "../assets/images/ports-menu.svg";
import { ReactComponent as RoutesIcon } from "../assets/images/routes-menu.svg";
import { ReactComponent as VesselIcon } from "../assets/images/ship.svg";
import { ReactComponent as BookingsIcon } from "../assets/images/bookings-menu.svg";
import { ReactComponent as ServicesIcon } from "../assets/images/services-menu.svg";
import { ReactComponent as OfficesIcon } from "../assets/images/offices-menu.svg";
import { ReactComponent as HSNIcon } from "../assets/images/hsn-menu.svg";
import { ReactComponent as CurrencyExchangeIcon } from "../assets/images/currencyExchange.svg";
import { ReactComponent as SettingsIcon } from "../assets/images/settings-menu.svg";
import { ReactComponent as UserRolesIcon } from "../assets/images/user-roles-menu.svg";
import { ReactComponent as UsersIcon } from "../assets/images/users-menu.svg";
import { ReactComponent as ExtraChargesIcon } from "../assets/images/extra-charges-menu.svg";
import { ReactComponent as ShippingLineIcon } from "../assets/images/shipping-line-menu.svg";
import { ReactComponent as ProfitMarginIcon } from "../assets/images/profit-margin-menu.svg";
import { ReactComponent as DoorIcon } from "../assets/images/Truck.svg";
import { IAppRoute } from "../interfaces/common";
const PortsPage = lazy(() => import("../pages/admin/ports"));
const ViewPorts = lazy(() => import("../pages/admin/ports/view-ports"));
const OfficesPage = lazy(() => import("../pages/admin/offices"));
const ViewOffices = lazy(() => import("../pages/admin/offices/viewOffices"));
const UserRolesPage = lazy(() => import("../pages/admin/user-roles"));
const EditViewUserRoles = lazy(
  () => import("../pages/admin/user-roles/view-edit-user-roles")
);
const UsersPage = lazy(() => import("../pages/admin/users"));
const ViewEditUser = lazy(() => import("../pages/admin/users/view-edit-users"));
const VesselSchedulesPage = lazy(
  () => import("../pages/admin/vessel-schedules")
);
const ViewVesselSchedules = lazy(
  () => import("../pages/admin/vessel-schedules/view-vessel-schedules")
);
const RoutesPage = lazy(() => import("../pages/admin/routes"));
const ViewRoutes = lazy(() => import("../pages/admin/routes/viewRoutes"));
const ServicesPage = lazy(() => import("../pages/admin/services"));
const AgentsPage = lazy(() => import("../pages/admin/agents"));
const ViewAgent = lazy(() => import("../pages/admin/agents/view-agent"));
const HSNPage = lazy(() => import("../pages/admin/hsn"));
const CurrencyRatePage = lazy(() => import("../pages/admin/currency-rate"));
const WarehousesPage = lazy(() => import("../pages/admin/warehouses"));
const BookingsPage = lazy(() => import("../pages/admin/bookings"));
const ViewBooking = lazy(() => import("../pages/admin/bookings/view-booking"));
const AppSettingsPage = lazy(() => import("../pages/admin/app-settings"));
const ExtraChargesPage = lazy(() => import("../pages/admin/extra-charges"));
const ViewExtraChargeDetails = lazy(
  () => import("../pages/admin/extra-charges/view-extra-charge")
);
const ChargesPage = lazy(() => import("../pages/admin/charges"));
const ViewChargeDetails = lazy(
  () => import("../pages/admin/charges/view-charge")
);
const ShippingLinesPage = lazy(() => import("../pages/admin/shipping-lines"));
const ViewShippingLine = lazy(
  () => import("../pages/admin/shipping-lines/view-shipping-line")
);
const DoorRulesPage = lazy(() => import("../pages/admin/door-rules"));
const ViewDoorRule = lazy(
  () => import("../pages/admin/door-rules/view-door-rule")
);
const ProfitMarginsPage = lazy(() => import("../pages/admin/profit-margins"));
const ViewProfitMargin = lazy(
  () => import("../pages/admin/profit-margins/view-profit-margin")
);

export const allRoutes: IAppRoute[] = [
  {
    key: "/home",
    label: "Home",
    route: "/home",
    icon: HomeIcon,
  },
  {
    key: "/ports",
    label: "Ports",
    route: "/ports?tab=all",
    icon: PortsIcon,
    component: PortsPage,
    children: [
      {
        key: "/ports/:portId",
        label: "Ports Details",
        route: "/ports/:portId",
        isNotNav: true,
        component: ViewPorts,
      },
    ],
  },
  {
    key: "/routes",
    label: "Routes",
    route: "/routes?tab=all",
    icon: RoutesIcon,
    component: RoutesPage,
    children: [
      {
        key: "/routes/:routeId",
        label: "Route Details",
        route: "/routes/:routeId",
        isNotNav: true,
        component: ViewRoutes,
      },
    ],
  },
  {
    key: "/vessel-schedules",
    label: "Vessel Schedules",
    route: "/vessel-schedules?tab=all",
    icon: VesselIcon,
    component: VesselSchedulesPage,
    children: [
      {
        key: "/vessel-schedules/:vesselId",
        label: "Vessel Schedule Details",
        route: "/vessel-schedules/:vesselId",
        isNotNav: true,
        component: ViewVesselSchedules,
      },
    ],
  },
  {
    key: "/charges",
    label: "Charges",
    route: "/charges",
    icon: ExtraChargesIcon,
    component: ChargesPage,
    children: [
      {
        key: "/charges/:chargeId",
        label: "Charge Details",
        route: "/charges/:chargeId",
        isNotNav: true,
        component: ViewChargeDetails,
      },
    ],
  },
  {
    key: "/bookings",
    label: "Bookings",
    route: "/bookings?tab=all",
    icon: BookingsIcon,
    component: BookingsPage,
    children: [
      {
        key: "/bookings/:bookingId",
        label: "Booking Details",
        route: "/bookings/:bookingId",
        isNotNav: true,
        component: ViewBooking,
      },
    ],
  },
  {
    key: "/services",
    label: "Services",
    route: "/services?tab=all",
    icon: ServicesIcon,
    component: ServicesPage,
  },
  {
    key: "/offices",
    label: "Offices",
    route: "/offices?tab=all",
    icon: OfficesIcon,
    component: OfficesPage,
    children: [
      {
        key: "/offices/:officeId",
        label: "Office Details",
        route: "/offices/:officeId",
        isNotNav: true,
        component: ViewOffices,
      },
    ],
  },
  {
    key: "/agents",
    label: "Agents",
    route: "/agents",
    icon: UsersIcon,
    component: AgentsPage,
    children: [
      {
        key: "/agents/:agentId",
        label: "Agent Details",
        route: "/agents/:agentId",
        isNotNav: true,
        component: ViewAgent,
      },
    ],
  },
  {
    key: "/hsn",
    label: "HSN",
    route: "/hsn",
    icon: HSNIcon,
    component: HSNPage,
  },
  {
    key: "/currency-rate",
    label: "Currency Rates",
    route: "/currency-rate",
    icon: CurrencyExchangeIcon,
    component: CurrencyRatePage,
  },
  {
    key: "/warehouses",
    label: "Warehouses",
    route: "/warehouses",
    icon: PortsIcon,
    component: WarehousesPage,
  },
  {
    key: "/extra-charges",
    label: "Extra Charges",
    route: "/extra-charges",
    icon: ExtraChargesIcon,
    component: ExtraChargesPage,
    children: [
      {
        key: "/extra-charges/:chargeId",
        label: "Extra Charge Details",
        route: "/extra-charges/:chargeId",
        isNotNav: true,
        component: ViewExtraChargeDetails,
      },
    ],
  },
  {
    key: "/profit-margins",
    label: "Profit Margins",
    route: "/profit-margins",
    icon: ProfitMarginIcon,
    component: ProfitMarginsPage,
    children: [
      {
        key: "/profit-margins/:marginId",
        label: "Profit Margins Details",
        route: "/profit-margins/:marginId",
        isNotNav: true,
        component: ViewProfitMargin,
      },
    ],
  },
  {
    key: "/shipping-lines",
    label: "Shipping Lines",
    route: "/shipping-lines?tab=all",
    icon: ShippingLineIcon,
    component: ShippingLinesPage,
    children: [
      {
        key: "/shipping-lines/:shippingLineId",
        label: "Shipping Lines Details",
        route: "/shipping-lines/:shippingLineId",
        isNotNav: true,
        component: ViewShippingLine,
      },
    ],
  },

  {
    key: "/settings",
    label: "Settings",
    icon: SettingsIcon,
    children: [
      {
        key: "/user-roles",
        label: "User Roles",
        route: "/user-roles",
        icon: UserRolesIcon,
        component: UserRolesPage,
        children: [
          {
            key: "/user-roles/:roleId",
            label: "User Role Details",
            route: "/user-roles/:roleId",
            isNotNav: true,
            component: EditViewUserRoles,
          },
          {
            key: "/user-roles-members/:userId",
            label: "User Role Member Details",
            route: "/user-roles-members/:userId",
            isNotNav: true,
            component: ViewEditUser,
          },
        ],
      },
      {
        key: "/users",
        label: "Users",
        route: "/users?tab=system",
        icon: UsersIcon,
        component: UsersPage,
        children: [
          {
            key: "/users/:userId",
            label: "User Details",
            route: "/users/:userId",
            isNotNav: true,
            component: ViewEditUser,
          },
        ],
      },
      {
        key: "/door-rules",
        label: "Door Rules",
        route: "/door-rules",
        icon: DoorIcon,
        component: DoorRulesPage,
        children: [
          {
            key: "/door-rules/:ruleId",
            label: "Door Rules",
            route: "/door-rules/:ruleId",
            isNotNav: true,
            component: ViewDoorRule,
          },
        ],
      },
      {
        key: "/app-settings",
        label: "App Settings",
        route: "/app-settings?tab=general_settings",
        icon: SettingsIcon,
        component: AppSettingsPage,
      },
    ],
  },
];

export const PermissionRouteMap = {
  USERS: ["/users", "/users/:userId"],
  ROLES: ["/user-roles", "/user-roles/:roleId", "/user-roles-members/:userId"],
  AGENTS: ["/agents", "/agents/:agentId"],
  OFFICES: ["/offices", "/offices/:officeId"],
  SERVICES: ["/services"],
  ROUTES: ["/routes", "/routes/:routeId"],
  VESSEL_SCHEDULES: ["/vessel-schedules", "/vessel-schedules/:vesselId"],
  PORTS: ["/ports", "/ports/:portId"],
  HSN: ["/hsn"],
  BOOKINGS: ["/bookings", "/bookings/:bookingId"],
  CURRENCY_RATES: ["/currency-rate"],
  WAREHOUSES: ["/warehouses"],
  EXTRA_CHARGES: ["/extra-charges", "/extra-charges/:chargeId"],
  SETTINGS: ["/app-settings"],
  CHARGES: ["/charges", "/charges/:chargeId"],
  SHIPPING_LINES: ["/shipping-lines", "/shipping-lines/:shippingLineId"],
  DOOR_RULES: ["/door-rules", "/door-rules/:ruleId"],
  PROFIT_MARGINS: ["/profit-margins", "/profit-margins/:marginId"],
};

export type PermissionType =
  | "VIEWUSERS"
  | "MANAGEUSERS"
  | "VIEWROLES"
  | "MANAGEROLES"
  | "VIEWAGENTS"
  | "MANAGEAGENTS"
  | "VIEWOFFICES"
  | "MANAGEOFFICES"
  | "VIEWSERVICES"
  | "MANAGESERVICES"
  | "VIEWROUTES"
  | "MANAGEROUTES"
  | "VIEWHSN"
  | "MANAGEHSN"
  | "VIEWVESSEL_SCHEDULES"
  | "MANAGEVESSEL_SCHEDULES"
  | "VIEWPORTS"
  | "MANAGEPORTS"
  | "VIEWBOOKINGS"
  | "MANAGEBOOKINGS"
  | "VIEWCURRENCY_RATES"
  | "MANAGECURRENCY_RATES"
  | "VIEWWAREHOUSES"
  | "MANAGEWAREHOUSES"
  | "VIEWSETTINGS"
  | "MANAGESETTINGS"
  | "VIEWEXTRA_CHARGES"
  | "MANAGEEXTRA_CHARGES"
  | "VIEWCHARGES"
  | "MANAGECHARGES"
  | "VIEWSHIPPING_LINES"
  | "MANAGESHIPPING_LINES"
  | "VIEWDOOR_RULES"
  | "MANAGEDOOR_RULES"
  | "VIEWPROFIT_MARGINS"
  | "MANAGEPROFIT_MARGINS";
