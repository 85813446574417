import { useMemo, useState } from "react";
import { Col, Form, Input, Row } from "antd";

import styles from "./changePasswordModal.module.scss";

const { Item } = Form;

const PasswordsField = () => {
  const [passwordValidity, setPasswordValidity] = useState({
    uppercase: false,
    lowercase: false,
    digit: false,
    special: false,
    length: false,
  });

  const validationsData = useMemo(() => {
    return [
      {
        name: "One lowercase character",
        check: passwordValidity.lowercase,
      },
      {
        name: "One special character",
        check: passwordValidity.special,
      },
      {
        name: "One uppercase character",
        check: passwordValidity.uppercase,
      },
      {
        name: "8 characters minimum",
        check: passwordValidity.length,
      },
      {
        name: "One number",
        check: passwordValidity.digit,
      },
    ];
  }, [passwordValidity]);

  return (
    <>
      <Row gutter={24}>
        <Col xs={24}>
          <Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter a password",
              },
              () => ({
                validator: (_, value) => {
                  const validity = {
                    uppercase: value && /[A-Z]/.test(value),
                    lowercase: value && /[a-z]/.test(value),
                    digit: value && /\d/.test(value),
                    special:
                      value && /[^a-zA-Z0-9\s]/.test(value) && /\S/.test(value),
                    length: value && value.length >= 8,
                  };

                  setPasswordValidity(validity);
                  if (value && Object.values(validity).some((val) => !val)) {
                    return Promise.reject(
                      new Error("Password doesn't satisfy policies!")
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Password"
              autoComplete="new-password"
            />
          </Item>
        </Col>
      </Row>

      <div className={styles["password-validation-wrapper"]}>
        {validationsData.map((validation) => {
          return (
            <div
              className={`${styles["validation"]} ${
                validation.check ? styles["validated"] : ""
              }`}
              key={validation.name}
            >
              <div className={styles["dot"]}></div>
              <p className={styles["validation-name"]}>{validation.name}</p>
            </div>
          );
        })}
      </div>

      <Row gutter={24}>
        <Col xs={24}>
          <Item
            label="Re-enter Password"
            name="confirm"
            rules={[
              {
                required: true,
                message: "Please confirm your password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !value ||
                    !getFieldValue("password") ||
                    getFieldValue("password") === value
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Password do not match"));
                },
              }),
            ]}
            style={{ marginBottom: 0 }}
            validateTrigger="onSubmit"
          >
            <Input.Password placeholder="Re-enter the password" />
          </Item>
        </Col>
      </Row>
    </>
  );
};

export default PasswordsField;
