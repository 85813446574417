import {
  useState,
  useEffect,
  Fragment,
  useRef,
  useMemo,
  lazy,
  Suspense,
} from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import axios from "axios";
import { Routes, Route, Navigate } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

// Components
import AuthLayout from "./components/layouts/auth-layout";
import AdminLayout from "./components/layouts/admin-layout";
import CustomerLayout from "./components/layouts/customer-layout";
import Spinner from "./components/custom-antd/spinner";
// Pages
import SignUp from "./pages/signup";
import Login from "./pages/login";
import HomePage from "./pages/customer/home";
import ForgotPassword from "./pages/forgot-password";
import ForgotPasswordSuccess from "./pages/forgot-password/success-page";
import ResetPassword from "./pages/reset-password";
import VerificationSuccess from "./pages/verification-success";
const CustomerBookingForm = lazy(() => import("./pages/customer/bookings"));
const SailingDetails = lazy(
  () => import("./pages/customer/bookings/sailing-details")
);
const FinalizingDetails = lazy(
  () => import("./pages/customer/bookings/finalizing-details")
);
const SummaryDetails = lazy(
  () => import("./pages/customer/bookings/summary-details")
);
const AdminHomePage = lazy(() => import("./pages/admin/home"));
const ManagePage = lazy(() => import("./pages/customer/manage"));
// Others
import {
  generatePermittedRoutes,
  getPermissionArr,
} from "./utils/permission-helpers";
import { getErrorToast } from "./utils/helper";
import { IUser } from "./interfaces/user";
import { IAppRoute, ICountry } from "./interfaces/common";
import { loginTokenKey } from "./constants";
import { AppContext } from "./hooks/appContext";

type GetRouteFuncType = (route: IAppRoute) => React.ReactElement;
dayjs.extend(customParseFormat);

const App = () => {
  const [user, setUser] = useState<IUser>();
  const tawkMessengerReactRef = useRef<any>(null);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const [countriesOptions, setCountriesOptions] = useState<ICountry[]>([]);
  const [contentWidth, setContentWidth] = useState(
    document?.documentElement?.clientWidth || 0
  );
  const [contentHeight, setContentHeight] = useState(
    document?.documentElement?.clientHeight || 0
  );

  useEffect(() => {
    const callback = () => {
      setContentWidth(document.documentElement.clientWidth);
      setContentHeight(document.documentElement.clientHeight);
    };
    window.addEventListener("resize", callback);
    return () => window.removeEventListener("resize", callback);
  }, []);

  const loadCurrentUser = (hideLoader?: boolean) => {
    const token = localStorage.getItem(loginTokenKey);

    const onFailure = () => {
      setUser(undefined);
      setLoadingUser(false);
    };

    if (token) {
      if (!hideLoader) {
        setLoadingUser(true);
      }
      axios
        .get(`users/me`)
        .then((response) => {
          const userResp = response?.data?.data;
          if (userResp) {
            const permissionsResp =
              userResp?.role?.rolePermissions?.map(
                (el: any) => el.permissionsMetadata
              ) || [];
            setUser({
              ...userResp,
              isAdmin: userResp.role?.name.toLowerCase() !== "customer",
              permissions: getPermissionArr(permissionsResp),
              routes: generatePermittedRoutes(permissionsResp),
            });
          } else {
            onFailure();
          }
        })
        .catch(() => onFailure())
        .finally(() => setLoadingUser(false));
    } else {
      onFailure();
    }
  };

  const getAllCountries = () => {
    axios
      .post(`countries/all`)
      .then((response) => {
        setCountriesOptions(response.data.data);
      })
      .catch((err) => getErrorToast("countries-get-error", err));
  };

  useEffect(() => {
    loadCurrentUser();
    getAllCountries();
  }, []);

  useEffect(() => {
    setTawkUser();
  }, [user?.email, user?.firstName, user?.lastName]);

  const setTawkUser = () => {
    if (tawkMessengerReactRef.current?.onLoaded() && user) {
      tawkMessengerReactRef.current.setAttributes({
        name: `${user.firstName} ${user.lastName || ""}`,
        email: user.email,
      });
    }
  };

  const getRoutes: GetRouteFuncType = (route: IAppRoute) => (
    <Fragment key={route.key}>
      {route.route && (
        <Route
          key={route.key}
          path={route.key}
          Component={route.component ?? AdminHomePage}
        />
      )}
      {!!route.children?.length &&
        route.children.map((childRoute: IAppRoute) => getRoutes(childRoute))}
    </Fragment>
  );

  const indianCountryId = countriesOptions?.find(
    (el) => el?.name?.toLowerCase() === "india"
  )?.id;

  const usaCountryId = countriesOptions?.find(
    (el) => el?.name?.toLowerCase() === "united states"
  )?.id;

  const possibleDoorCountries: string[] = [];
  if (indianCountryId) possibleDoorCountries.push(indianCountryId);
  if (usaCountryId) possibleDoorCountries.push(usaCountryId);

  const providerValues = useMemo(() => {
    return {
      loadingUser,
      loadCurrentUser,
      user,
      setUser,
      countriesOptions,
      indianCountryId,
      possibleDoorCountries,
      contentWidth,
      contentHeight,
    };
  }, [
    loadingUser,
    user,
    countriesOptions,
    indianCountryId,
    contentWidth,
    contentHeight,
  ]);

  return (
    <AppContext.Provider value={providerValues}>
      {loadingUser ? (
        <Spinner />
      ) : (
        <>
          {user &&
            (!user.isAdmin ? (
              <CustomerLayout>
                <Suspense fallback={<Spinner />}>
                  <Routes>
                    <Route path="/" element={<Navigate to="/home" />} />
                    <Route path="/home" Component={HomePage} />
                    <Route path="/booking" Component={CustomerBookingForm} />
                    {[
                      "/booking/:bookingId",
                      "/manage-booking/:bookingId",
                      "/manage-booking/template/:templateId",
                    ].map((el) => (
                      <Route
                        key={el}
                        path={el}
                        element={<CustomerBookingForm key={el} />}
                      />
                    ))}
                    {[
                      "/booking/sailing-details/:bookingId",
                      "/manage-booking/sailing-details/:bookingId",
                    ].map((el) => (
                      <Route key={el} path={el} Component={SailingDetails} />
                    ))}

                    {[
                      "/booking/finalizing-details/:bookingId",
                      "/manage-booking/finalizing-details/:bookingId",
                    ].map((el) => (
                      <Route key={el} path={el} Component={FinalizingDetails} />
                    ))}
                    {[
                      "/booking/summary-details/:bookingId",
                      "/manage-booking/summary-details/:bookingId",
                    ].map((el) => (
                      <Route key={el} path={el} Component={SummaryDetails} />
                    ))}
                    <Route path="/manage/:tab" Component={ManagePage} />
                    <Route path="*" element={<Navigate to="/home" />} />
                  </Routes>
                </Suspense>
              </CustomerLayout>
            ) : (
              <AdminLayout>
                <Suspense fallback={<Spinner />}>
                  <Routes>
                    <Route path="/" element={<Navigate to="/home" />} />
                    <Route path="/home" Component={AdminHomePage} />
                    {user.routes.map((route) => getRoutes(route))}
                    <Route path="*" element={<Navigate to="/home" />} />
                  </Routes>
                </Suspense>
              </AdminLayout>
            ))}

          {!user && (
            <AuthLayout>
              <Routes>
                <Route path="/" Component={Login} />
                <Route path="/signup" Component={SignUp} />
                <Route path="/login" Component={Login} />
                <Route path="/forgot-password" Component={ForgotPassword} />
                <Route
                  path="/forgot-password-success"
                  Component={ForgotPasswordSuccess}
                />
                <Route path="/reset-password" Component={ResetPassword} />
                <Route path="/verify-user" Component={VerificationSuccess} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </AuthLayout>
          )}
        </>
      )}

      {!loadingUser && process.env.NODE_ENV !== "development" && (
        <TawkMessengerReact
          propertyId={process.env.REACT_APP_TAWK_TO_PROPERTY_ID}
          widgetId={process.env.REACT_APP_TAWK_TO_WIDGET_ID}
          ref={tawkMessengerReactRef}
          onLoad={() => setTawkUser()}
        />
      )}
    </AppContext.Provider>
  );
};

export default App;
