import { useState } from "react";
import { Button, Form, Input } from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import { getErrorToast } from "../../utils/helper";

import styles from "./forgotPassword.module.scss";

const { Item } = Form;

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (data: { email: string }) => {
    setSubmitting(true);
    axios
      .post(`users/forgot-password`, data)
      .then(() =>
        navigate("/forgot-password-success", {
          state: { email: data.email },
        })
      )
      .catch((err) => getErrorToast("forgot-password-error", err))
      .finally(() => setSubmitting(false));
  };

  return (
    <Form
      className={styles["login-form"]}
      layout="vertical"
      onFinish={onSubmit}
      disabled={submitting}
      scrollToFirstError
    >
      <h1>Forgot Password</h1>
      <Item
        label="Email address"
        name="email"
        rules={[
          {
            required: true,
            whitespace: true,
            message: "Please enter your email address",
          },
          { type: "email", message: "Please enter a valid email address" },
        ]}
      >
        <Input placeholder="Enter email address" />
      </Item>
      <Button type="primary" htmlType="submit" loading={submitting}>
        Request Password Reset
      </Button>
      {!submitting && (
        <div className={styles["back-to-login"]}>
          <Link to="/">Back to Login</Link>
        </div>
      )}
    </Form>
  );
};

export default ForgotPassword;
