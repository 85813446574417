import { Spin, SpinProps } from "antd";

import styles from "./spinner.module.scss";

interface IProps extends SpinProps {
  wrapperClassName?: string;
}

const Spinner = (props: IProps) => {
  const { wrapperClassName, ...rest } = props;
  return (
    <div className={`${styles["spinner-wrapper"]} ${wrapperClassName ?? ""}`}>
      <Spin {...rest} />
    </div>
  );
};

export default Spinner;
