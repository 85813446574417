import { bookingStatus } from "../../constants";

import styles from "./bookingStatus.module.scss";

const CustomerBookingStatus = (props: { status: string }) => {
  const { status } = props;

  const IconComponent = bookingStatus[status]?.icon;

  return (
    <>
      {status && (
        <div className={styles["status-wrapper"]}>
          <div
            className={styles["status"]}
            style={{
              color: bookingStatus[status].color,
              background: bookingStatus[status].background,
            }}
          >
            {IconComponent && <IconComponent />}
            {bookingStatus[status].title}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerBookingStatus;
