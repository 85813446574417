export const gstRegex = /^\d{2}[A-Z]{5}\d{4}[0-9A-Z][1-9A-Z]Z[0-9A-Z]$/;
export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const timeTZRegex = /T\d{2}:\d{2}:\d{2}\.\d{3}Z/;
export const noSpaceRegex = /^\S+$/;
export const alphanumericWithSpaceRegex = /^[A-Za-z0-9\s]+$/;
export const emailInStringRegex =
  /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
export const indianZipCodeRegex = /^\d{6}$/;
export const urlRegex =
  /^(http[s]?:\/\/)(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/;
