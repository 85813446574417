import { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { getErrorToast, getSuccessToast, useQuery } from "../../utils/helper";
import Spinner from "../../components/custom-antd/spinner";
import PasswordsField from "../../components/change-password-modal/passwordsField";

import styles from "./reset.module.scss";

const { Item } = Form;

interface IResetFormValues {
  email: string;
  password: string;
  confirm?: string;
}

const ResetPassword = () => {
  const [verifying, setVerifying] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const query = useQuery();
  const token = query.get("token");

  const verifyUser = () => {
    setVerifying(true);
    axios
      .get(`users/verify-token`, { params: { token } })
      .then((response) => {
        form.setFieldValue("email", response.data.data.email);
      })
      .catch((err) => getErrorToast("verify-user-error", err))
      .finally(() => setVerifying(false));
  };

  useEffect(() => {
    verifyUser();
  }, []);

  const onSubmit = (data: IResetFormValues) => {
    delete data.confirm;
    setSubmitting(true);
    axios
      .post(`users/reset-password`, data, { params: { token } })
      .then((response) => {
        getSuccessToast("reset-password-success", response);
        navigate("/");
      })
      .catch((err) => getErrorToast("reset-password-error", err))
      .finally(() => setSubmitting(false));
  };

  return (
    <Form
      form={form}
      className={styles["reset-form"]}
      layout="vertical"
      onFinish={onSubmit}
      disabled={submitting}
      scrollToFirstError
    >
      {verifying ? (
        <Spinner />
      ) : (
        <>
          <h1>Reset Password</h1>
          <Row gutter={24}>
            <Col xs={24}>
              <Item label="Email" name="email">
                <Input disabled />
              </Item>
            </Col>
          </Row>

          <PasswordsField />

          <Button type="primary" htmlType="submit" loading={submitting}>
            Reset Password
          </Button>
        </>
      )}
    </Form>
  );
};

export default ResetPassword;
