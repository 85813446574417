import { PermissionRouteMap, allRoutes } from "../constants/permission-routes";
import {
  IAppRoute,
  IPermission,
  IPermissionTableData,
} from "../interfaces/common";

/**
 * Generates a route permission map based on the PermissionRouteMap object.
 *
 * @returns {Object} - A map of routes to their corresponding permissions. Below is an example of output.
 *   {
 *     "/users": "USERS",
 *     "/user-roles": "ROLES",
 *   }
 */
export const getRoutePermissionMap = () => {
  const obj: { [key: string]: string } = {};
  Object.entries(PermissionRouteMap).forEach((el) => {
    el[1].forEach((route) => {
      obj[route] = el[0];
    });
  });
  return obj;
};

/**
 * Generates an array of permission objects based on the input permission array.
 *
 * @param {Array} permissions - An array of permission objects.
 *   Each permission object should have the following structure:
 *   {
 *     name: string, // Name of the permission
 *     id: number,   // ID of the permission
 *   }
 *
 * @returns {Array} - An array of processed permission objects.
 *   Each processed permission object has the following structure:
 *   {
 *     name: string,      // Name of the permission without 'VIEW' or 'MANAGE' prefixes
 *     viewId: number,    // ID of the permission if it's a 'VIEW' permission, or an empty string
 *     manageId: number,  // ID of the permission if it's a 'MANAGE' permission, or an empty string
 *   }
 */
export const generatePermissionArr = (permissions: IPermission[] = []) => {
  const tempArr: IPermissionTableData[] = [];
  permissions.forEach((permission) => {
    if (permission.name && !permission.name.includes("CURRENT")) {
      const permissionName = permission.name.replace(/^(VIEW|MANAGE)/, "");
      const isViewPermission = permission.name.startsWith("VIEW");
      const isManagePermission = permission.name.startsWith("MANAGE");

      const alreadyExistingIndex = tempArr.findIndex(
        (el) => el.name === permissionName
      );
      if (alreadyExistingIndex > -1) {
        tempArr[alreadyExistingIndex][
          isViewPermission ? "viewId" : "manageId"
        ] = permission.id;
      } else {
        tempArr.push({
          name: permissionName,
          viewId: isViewPermission ? permission.id : "",
          manageId: isManagePermission ? permission.id : "",
        });
      }
    }
  });

  return tempArr;
};

/**
 * Generates an array of permission strings based on the input permission array.
 *
 * @param {Array} permission - An array of permission objects.
 *   Each permission object should have the following structure:
 *   {
 *     name: string, // Name of the permission
 *     id: number,   // ID of the permission
 *   }
 *
 * @return {Array} - An array of permission string e.g. ["MANAGEUSERS", "MANAGEROLES"].
 */
export const getPermissionArr = (permission: IPermission[] = []) => {
  return permission.map((el) => el.name);
};

export const generatePermittedRoutes = (permissions: IPermission[] = []) => {
  const routePermissionMap = getRoutePermissionMap();
  const permissionArr = getPermissionArr(permissions).map((el) =>
    el.replace(/^(VIEW|MANAGE)/, "")
  );
  const filterRoutes = (routes: IAppRoute[]) => {
    const tempRoutes: IAppRoute[] = [];
    routes.forEach((routeEl) => {
      const childRoutes: IAppRoute[] = filterRoutes(routeEl.children ?? []);
      if (
        permissionArr.includes(routePermissionMap[routeEl.key]) ||
        childRoutes.length
      ) {
        tempRoutes.push({
          ...routeEl,
          children: childRoutes?.length ? childRoutes : undefined,
        });
      }
    });
    return tempRoutes;
  };

  return filterRoutes(allRoutes);
};
