import { useState, useEffect } from "react";
import { Form, Divider, Row, Col, Input, Button } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";

import {
  getErrorToast,
  getSuccessToast,
  isValidGSTNumber,
} from "../../utils/helper";
import { useAppContext } from "../../hooks/appContext";
import MobileInput from "../../components/form-fields/mobile-input";
import { ISignupData } from "../../interfaces/user";
import CustomSelect from "../../components/custom-antd/select";
import PasswordsField from "../../components/change-password-modal/passwordsField";
import Maintenance from "../../assets/images/maintenance.svg";
import { customerType } from "../../constants";
import Spinner from "../../components/custom-antd/spinner";

import styles from "./signup.module.scss";

interface IVerifySignup {
  loading: boolean;
  data?: {
    isCustomerSignupEnabled: boolean;
  };
}

const { Item } = Form;

const SignUp = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const watchCountry = Form.useWatch("countryId", form);
  const { countriesOptions, indianCountryId } = useAppContext();
  const isSelectedCountryIndian = watchCountry === indianCountryId;

  const [submitting, setSubmitting] = useState(false);
  const [officeOptions, setOfficeOptions] = useState([]);
  const [verifySignupData, setVerifySignupData] = useState<IVerifySignup>({
    loading: true,
    data: undefined,
  });

  useEffect(() => {
    getAllOffices();
    verifySignUpStatus();
  }, []);

  const onSubmit = (data: ISignupData) => {
    setSubmitting(true);
    delete data.confirm;
    data.lastName = data.lastName ? data.lastName : null;
    data.phone = data.phone ? data.phone : null;
    axios
      .post(`users`, data)
      .then((response) => {
        getSuccessToast("signup-success", response);
        navigate("/login", { replace: true });
      })
      .catch((err) => getErrorToast("signup-error", err))
      .finally(() => setSubmitting(false));
  };

  const getAllOffices = () => {
    axios
      .post(`offices/all`)
      .then((response) => {
        const officeData = response.data.data;
        setOfficeOptions(officeData);
      })
      .catch((err) => getErrorToast("office-get-error", err))
      .finally(() => setSubmitting(false));
  };

  const verifySignUpStatus = () => {
    setVerifySignupData((prev) => ({ ...prev, loading: true }));
    axios
      .get("users/verify-signup")
      .then((response) => {
        setVerifySignupData((prev) => ({ ...prev, data: response.data.data }));
      })
      .catch((err) => getErrorToast("office-get-error", err))
      .finally(() => {
        setVerifySignupData((prev) => ({ ...prev, loading: false }));
      });
  };

  return (
    <>
      {verifySignupData.loading ? (
        <Spinner />
      ) : verifySignupData.data?.isCustomerSignupEnabled ? (
        <Form
          form={form}
          layout="vertical"
          className={styles["signup-form"]}
          onFinish={onSubmit}
          disabled={submitting}
          scrollToFirstError
        >
          <h1 className={styles["create-account-title"]}>Create an Account</h1>
          <Item
            label="EMU Controlling Office "
            name="officeId"
            rules={[
              {
                required: true,
                message: "Please select EMU controlling office",
              },
            ]}
            validateTrigger="onSubmit"
          >
            <CustomSelect
              showSearch
              allowClear
              placeholder="Select EMU Controlling Office"
              options={officeOptions.map((office: any) => ({
                label: office.name,
                value: office.id,
              }))}
              optionFilterProp="label"
            />
          </Item>

          <Divider style={{ margin: "19px 0px" }} />

          <Row gutter={20}>
            <Col xs={12}>
              <Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter your first name",
                  },
                ]}
                validateTrigger="onSubmit"
              >
                <Input placeholder="First name" />
              </Item>
            </Col>

            <Col xs={12}>
              <Item label="Last Name" name="lastName">
                <Input placeholder="Last name" />
              </Item>
            </Col>

            <Col xs={12}>
              <Item
                label="Company"
                name="company"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter the name of your company",
                  },
                ]}
                validateTrigger="onSubmit"
              >
                <Input placeholder="Company" />
              </Item>
            </Col>

            <Col xs={12}>
              <Item
                label="Country"
                name="countryId"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please select a country",
                  },
                ]}
                validateTrigger="onSubmit"
              >
                <CustomSelect
                  showSearch
                  placeholder="Country"
                  options={countriesOptions.map((country) => ({
                    label: country.name,
                    value: country.id,
                  }))}
                  optionFilterProp="label"
                  onChange={() => {
                    form.setFieldValue("phone", undefined);
                    form.setFieldValue("gstNumber", undefined);
                  }}
                />
              </Item>
            </Col>

            <Col xs={12}>
              <Item
                label="Email address"
                name="email"
                rules={[
                  { type: "email", message: "Please enter a valid email" },
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter your email",
                  },
                ]}
                validateTrigger="onSubmit"
              >
                <Input placeholder="Email address" />
              </Item>
            </Col>

            <Col xs={12}>
              <Item
                label="Mobile"
                name="phone"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        value &&
                        !isValidPhoneNumber(
                          `${value}`?.startsWith("+") ? value : `+${value}`
                        )
                      ) {
                        return Promise.reject(
                          new Error("Please input valid mobile number!")
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
                validateTrigger="onSubmit"
              >
                <MobileInput
                  wrapperClass={styles["mobile-input-wrapper"]}
                  selectedCountry={watchCountry}
                />
              </Item>
            </Col>

            <Col xs={isSelectedCountryIndian ? 12 : 24}>
              <Item
                label="Customer Type"
                name="userType"
                rules={[
                  { required: true, message: "Please select customer type" },
                ]}
                validateTrigger="onSubmit"
              >
                <CustomSelect
                  showSearch
                  placeholder="Customer Type"
                  options={Object.keys(customerType).map((el) => ({
                    label: customerType[el],
                    value: el,
                  }))}
                  optionFilterProp="label"
                />
              </Item>
            </Col>

            {isSelectedCountryIndian && (
              <Col xs={12}>
                <Item
                  label="GSTIN"
                  name="gstNumber"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "GSTIN is required for India",
                    },
                    () => ({
                      validator(_, value) {
                        if (value && !isValidGSTNumber(value)) {
                          return Promise.reject(
                            new Error("Please input valid GSTIN")
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                  validateTrigger="onSubmit"
                >
                  <Input placeholder="GSTIN" />
                </Item>
              </Col>
            )}
          </Row>

          <PasswordsField />

          <p className={styles["policy-text"]}>
            By signing up you understand and agree with our{""}
            <a href="/files/Sign-up-T&C.pdf" target="_blank">
              Terms of Service and Privacy Policy
            </a>
          </p>

          <Button type="primary" htmlType="submit" loading={submitting}>
            Register Now
          </Button>
        </Form>
      ) : (
        <div className={styles["signup-disable-wrapper"]}>
          <img src={Maintenance} alt="Success" width={100} />
          <p className={styles["message"]}>We&apos;ll Be Right Back!</p>
          <p className={styles["message"]}>
            We&apos;re just making some updates to improve your experience.
          </p>
          <p className={styles["message"]}>
            We&apos;ll be back shortly. Thank you for your patience!
          </p>
        </div>
      )}
    </>
  );
};

export default SignUp;
