import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Success from "../../../assets/images/success.svg";

import styles from "./successPage.module.scss";

const ForgotPasswordSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const email = location.state?.email;

  useEffect(() => {
    if (!email) {
      navigate("/", { replace: true });
    }
  }, [email]);

  return (
    <div className={styles["success-img"]}>
      <img src={Success} alt="Success" width={60} />
      <p className={styles["message"]}>
        {`You'll get an email at ${email} if it's linked to your account`}
      </p>
    </div>
  );
};

export default ForgotPasswordSuccess;
