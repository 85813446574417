import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, Layout, Menu, Popover, Tour, TourProps } from "antd";
import axios from "axios";
import { LockTwoTone } from "@ant-design/icons";

import Container from "../../containers";
import PrivateLogo from "../../../assets/images/LogoPrivate.svg";
import NewBookingStep1TourImage from "../../../assets/images/tour-new-booking-step-1.webp";
import NewBookingStep2TourImage from "../../../assets/images/tour-new-booking-step-2.webp";
import SailingDetailTourImage from "../../../assets/images/tour-sailing-detail.webp";
import FinaliseDetailTourImage from "../../../assets/images/tour-finalise-detail.webp";
import BookingSummaryTourImage from "../../../assets/images/tour-booking-summary.webp";
import ManageBookingsTourImage from "../../../assets/images/tour-manage-bookings.webp";
import { ReactComponent as LogOut } from "../../../assets/images/sign-out.svg";
import { handleLogout } from "../../../utils/helper";
import { useAppContext } from "../../../hooks/appContext";
import ChangePasswordModal from "../../change-password-modal";

import styles from "./privateHeader.module.scss";

const { Header } = Layout;

const PrivateHeader = forwardRef(function PrivateHeader(props, ref) {
  const { user, setUser, loadCurrentUser } = useAppContext();
  const naviagte = useNavigate();
  const location = useLocation();

  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isChangePassOpen, setIsChangePassOpen] = useState(false);
  const newBookingRef = useRef<HTMLAnchorElement>(null);
  const manageRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (user?.showWalkThorugh) {
      setIsTourOpen(true);
    }
  }, [user?.showWalkThorugh]);

  const onTourClose = () => {
    setIsTourOpen(false);
  };

  const onTourFinish = () => {
    onTourClose();
    if (user?.showWalkThorugh && loadCurrentUser) {
      axios
        .put("customer/me", { showWalkThorugh: false })
        .then(() => loadCurrentUser(true))
        .catch(() => null);
    }
  };

  const steps: TourProps["steps"] = [
    {
      title: "New Booking",
      description:
        "Click here to create a new booking. You can choose your pickup and delivery ports from this page.",
      cover: <img alt="new-booking" src={NewBookingStep1TourImage} />,
      target: newBookingRef.current,
    },
    {
      title: "New Booking (continued)",
      description: `Once you've added the details of your cargo and information about the packages, click on the "Continue" button to proceed.`,
      cover: <img alt="new-booking" src={NewBookingStep2TourImage} />,
      target: newBookingRef.current,
    },
    {
      title: "Sailing Details",
      description:
        "After creating a booking, our system will process it and display the shipments. You can select the one that suits you.",
      cover: <img alt="new-booking" src={SailingDetailTourImage} />,
      target: newBookingRef.current,
    },
    {
      title: "Finalise the details",
      description:
        "You are now just a step away from completing your booking. Have an overview of all the details and complete your booking.",
      cover: <img alt="new-booking" src={FinaliseDetailTourImage} />,
      target: newBookingRef.current,
    },
    {
      title: "Booking Summary",
      description:
        "Hurray, your booking is complete! You can view the summary here.",
      cover: <img alt="new-booking" src={BookingSummaryTourImage} />,
      target: newBookingRef.current,
    },
    {
      title: "Manage",
      description: "Manage your bookings from this section.",
      cover: <img alt="manage" src={ManageBookingsTourImage} />,
      target: manageRef.current,
    },
  ];

  const menuItems = useMemo(() => {
    return [
      {
        label: <Link to="/home">Home</Link>,
        key: "home",
      },
      {
        label: (
          <Link ref={newBookingRef} to="/booking">
            New Booking
          </Link>
        ),
        key: "booking",
      },
      {
        label: (
          <Link ref={manageRef} to="/manage/bookings">
            Manage
          </Link>
        ),
        key: "manage",
      },
    ];
  }, []);

  const selectedKey = location.pathname.startsWith("/manage-booking")
    ? "manage"
    : location.pathname.split("/")[1];
  const userName = `${user?.firstName} ${user?.lastName ?? ""}`;
  const userEmail = user?.email;
  const initials = `${user?.firstName?.[0]?.toUpperCase()}${
    user?.lastName?.[0]?.toUpperCase() ?? ""
  }`;

  return (
    <>
      <Header className={styles["private-header"]} ref={ref as any}>
        <Container className={styles["header-content"]}>
          <Button
            type="link"
            onClick={() => naviagte("/")}
            style={{
              padding: 0,
              height: "auto",
              border: "none",
              lineHeight: 0,
            }}
          >
            <img
              src={PrivateLogo}
              alt="PrivateLogo"
              className={styles["private-logo"]}
            />
          </Button>

          <Menu
            mode="horizontal"
            items={menuItems}
            selectedKeys={[selectedKey]}
          />
          <div className={styles["header-image-container"]}>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => setIsTourOpen(true)}
            >
              Begin Tour
            </Button>
            <Popover
              placement="bottomRight"
              trigger="click"
              open={isPopoverOpen}
              onOpenChange={setIsPopoverOpen}
              overlayClassName={styles["profile-popover"]}
              getPopupContainer={(triggerNode) => triggerNode.parentNode as any}
              title={
                <div className={styles["profile-popover-title-wrapper"]}>
                  <Avatar
                    src={user?.profilePicture}
                    style={{ background: user?.rgbIcon }}
                    size={40}
                    className={styles["profile-popover-avatar"]}
                  >
                    {initials}
                  </Avatar>
                  <div style={{ overflowWrap: "anywhere" }}>
                    <div className={styles["profile-popover-name"]}>
                      {userName}
                    </div>
                    <div className={styles["profile-popover-email"]}>
                      {userEmail}
                    </div>
                  </div>
                </div>
              }
              content={
                <div className={styles["profile-popover-content-wrapper"]}>
                  <div className={styles["profile-popover-content-password"]}>
                    <LockTwoTone />
                    <Button
                      type="link"
                      onClick={() => {
                        setIsPopoverOpen(false);
                        setIsChangePassOpen(true);
                      }}
                    >
                      Change Password
                    </Button>
                  </div>
                  <Button
                    type="link"
                    className={styles["profile-popover-content-logout"]}
                    onClick={() => handleLogout(setUser)}
                  >
                    <LogOut width={14} height={14} />
                    <p>Logout</p>
                  </Button>
                </div>
              }
              arrow={{ pointAtCenter: true }}
            >
              <Avatar
                src={user?.profilePicture}
                style={{ background: user?.rgbIcon }}
                size={32}
                className="cursor-pointer"
              >
                {initials}
              </Avatar>
            </Popover>
          </div>
        </Container>
      </Header>

      <Tour
        open={isTourOpen}
        onClose={onTourClose}
        onFinish={onTourFinish}
        steps={steps}
        rootClassName={styles["tour"]}
      />

      <ChangePasswordModal
        isOpen={isChangePassOpen}
        onClose={() => setIsChangePassOpen(false)}
        submitUrl="customer/me"
      />
    </>
  );
});

export default PrivateHeader;
